<template>
	<div>
		<a-spin :spinning="loading">
		<div class="ui-headerStyle">
			<div class="ui-stepStyle">
				<a-steps :current="current">
					<a-step v-for="(item,index) in steps" :key="item.title" :title="item.title" />
				</a-steps>
			</div>
			<!-- <div class="ui-stepsTip">
				<a-button type="primary" @click="onPreviewBtn">预览全部/取消预览</a-buttonums>
			</div> -->
			<!-- <a-tabs v-model:activeKey="current" style="margin-top: 10px;" v-show="!isPreview">
				<a-tab-pane v-for="(item,index) in steps" :key="index" :tab="item.title"></a-tab-pane>
			</a-tabs> -->
			<!-- <p v-show="!isPreview" style="margin: 0;">单位：元</p> -->
		</div>
		<a-back-top />
		<!-- <div class="ui-height2"></div> -->
		<!-- 整体预览 -->
		<div>
			<a-affix :offset-top="80" style="width:150px;height:180px;right:calc(35px);top:20%;position:fixed;z-index:999;background-color: #fff">
				<a-anchor>
					<a-anchor-link v-if="current >= 0" href="#baseInfo" title="基本信息"></a-anchor-link>
					<a-anchor-link v-if="current >= 1" href="#useland" title="建设用地储备"></a-anchor-link>
					<a-anchor-link v-if="current >= 2" href="#approval" title="项目立项"></a-anchor-link>
					<a-anchor-link v-if="current >= 3" href="#inviteTenders" title="项目招标"></a-anchor-link>
					<a-anchor-link v-if="current >= 4" href="#build" title="项目施工"></a-anchor-link>
					<a-anchor-link v-if="current >= 5" href="#finish" title="竣工验收"></a-anchor-link>
				</a-anchor>
			</a-affix>
			<!-- 基本信息 -->
			<div id="baseInfo" class="ui-moduleBox steps-content" v-if="current >= 0">
				<div>
					<modalHeader title="基本信息"></modalHeader>
				</div>
				<div class="ui-cutApart"></div>
				<a-descriptions title="基本情况" size="small" bordered layout="horizontal">
					<a-descriptions-item label="项目名称" style="width: 300px;">{{projectInfo.name || ''}}
					</a-descriptions-item>
					<a-descriptions-item label="项目分类" style="width: 300px;">{{projectInfo.classifyName || ''}}
					</a-descriptions-item>
					<a-descriptions-item label="主管单位" style="width: 300px;">{{projectInfo.mangerUnit || ''}}
					</a-descriptions-item>
					<a-descriptions-item label="主管单位联系人" style="width: 300px;">{{projectInfo.mangerUsername|| ''}}
					</a-descriptions-item>
					<a-descriptions-item label="主管单位联系人电话" style="width: 300px;">{{projectInfo.mangerUserPhone|| ''}}
					</a-descriptions-item>
					<a-descriptions-item label="启动时间：" style="width: 300px;">{{transDateTime(projectInfo.bootTime)|| ''}}
					</a-descriptions-item>
					<a-descriptions-item label="项目基本情况" style="width: 300px;">
						{{projectInfo.description}}
					</a-descriptions-item>
				</a-descriptions>
				<div class="ui-cutApart"></div>
				<a-descriptions title="实施单位信息" size="middle" bordered layout="horizontal">
					<a-descriptions-item label="实施单位：" style="width: 300px;">{{projectInfo.executeUnit|| ''}}
					</a-descriptions-item>
					<a-descriptions-item label="项目负责人：" style="width: 300px;">{{projectInfo.headUsername|| ''}}
					</a-descriptions-item>
					<a-descriptions-item label="分管领导：" style="width: 300px;">{{projectInfo.leaderUsername|| ''}}
					</a-descriptions-item>
					<a-descriptions-item label="项目负责人电话：" style="width: 300px;">{{projectInfo.headUserPhone|| ''}}
					</a-descriptions-item>
					<a-descriptions-item label="分管领导电话：" style="width: 300px;">{{projectInfo.leaderPhone|| ''}}
					</a-descriptions-item>
				</a-descriptions>
				<p class="ui-contentMarginTop">资金总览</p>
				<cTable :rowClassName="rowClassName" :columns="fundsTable" :dataList="fundsDataSource" :pagination="false" bordered :isPage="false">
				</cTable>
				<p class="ui-explain">说明：如有土地出让金、有偿划拨金、协议转让金，则征拆资金和土地报批费用不计入土地成本。</p>
				<p class="ui-contentMarginTop">办证情况</p>
				<cTable :columns="fundsDataCertificateTable" :dataList="fundsDataCertificate" :pagination="false" bordered :isPage="false">
				</cTable>
				<!-- <a-table :columns="fundsTable" :data-source="fundsDataSource" :pagination="false" :size="small" :customRow="customRow"></a-table> -->
			</div>
			<!-- 建设用地储备 -->
			<div id="useland" class="ui-moduleBox steps-content" v-if="current >= 1">
				<div>
					<modalHeader title="建设用地储备"></modalHeader>
				</div>
				<div v-if="stageInfos?.buildLandFlagHasStage == '1'||stageInfos?.buildLandFlagHasStage == '2'">
					<p class="ui-contentMarginTop" v-if="stageInfos?.buildLandFlagHasStage == '1'">不涉及新增用地</p>
					<p class="ui-contentMarginTop" v-if="stageInfos?.buildLandFlagHasStage == '2'">存量建设用地</p>
					说明：
					<a-textarea style="margin-bottom:30px;" v-model:value="stageInfos.buildLandFlagNotes" :rows="4"/>
				</div>
				<div v-else>
					<p class="ui-contentMarginTop">阶段信息</p>
					<cTable :columns="landTable" :dataList="landDataSource" bordered :isPage="false"></cTable>
					<!-- <a-table :columns="landTable" :data-source="landDataSource" :pagination="false"></a-table> -->
					<p class="ui-contentMarginTop">阶段费用</p>
					<cTable :rowClassName="rowClassName" :columns="fundsTable" :dataList="stagePriceDataSource" bordered :isPage="false"></cTable>
					<p class="ui-explain">说明：如有土地出让金、有偿划拨金、协议转让金，则征拆资金和土地报批费用不计入土地成本。</p>
					<p class="ui-contentMarginTop">阶段资金凭证</p>
					<div class="ui-fileStyle">
						<div class="ui-common" v-if="fundVoucher.length != 0">
							<div class="ui-common__item" v-for="(item,index) in fundVoucher" :key="index">
								<a :href="item" target="_blank">
									<Icon icon="FilePdfOutlined" />{{ fileNameFun(item) }}
								</a>
							</div>
						</div>
						<div v-else>
							<a-empty />
						</div>
					</div>
					<p class="ui-contentMarginTop">阶段文件</p>
					<div class="ui-fileStyle">
						<div class="ui-common" v-if="stageFiles.length != 0">
							<div class="ui-common__item" v-for="(item,index) in stageFiles" :key="index">
								<a :href="item" target="_blank">
									<Icon icon="FilePdfOutlined" />{{ fileNameFun(item) }}
								</a>
							</div>
						</div>
						<div v-else>
							<a-empty />
						</div>
					</div>
				</div>
			</div>
			<!-- 项目立项 -->
			<div id="approval" class="ui-moduleBox steps-content" v-if="current >= 2">
				<div>
					<modalHeader title="项目立项"></modalHeader>
				</div>
				<p class="ui-contentMarginTop">阶段信息</p>
				<cTable :columns="approvalTable" :dataList="approvalDataSource" bordered :isPage="false"></cTable>
				<p class="ui-contentMarginTop">阶段费用</p>
				<cTable :columns="fundsTable" :dataList="approvalDataCost" bordered :isPage="false"></cTable>
				<p class="ui-contentMarginTop">阶段资金凭证</p>
				<div class="ui-fileStyle">
					<div class="ui-common" v-if="approvalVoucher.length != 0">
						<div class="ui-common__item" v-for="(item,index) in approvalVoucher" :key="index">
							<a :href="item" target="_blank">
								<Icon icon="FilePdfOutlined" />{{ fileNameFun(item) }}
							</a>
						</div>
					</div>
					<div v-else>
						<a-empty />
					</div>
				</div>
				<p class="ui-contentMarginTop">阶段文件</p>
				<div class="ui-fileStyle">
					<div class="ui-common" v-if="approvalFiles.length != 0">
						<div class="ui-common__item" v-for="(item,index) in approvalFiles" :key="index">
							<a :href="item" target="_blank">
								<Icon icon="FilePdfOutlined" />{{ fileNameFun(item) }}
							</a>
						</div>
					</div>
					<div v-else>
						<a-empty />
					</div>
				</div>
			</div>
			<div id="inviteTenders" class="ui-moduleBox steps-content" v-if="current >= 3">
				<div>
					<modalHeader title="项目招标"></modalHeader>
				</div>
				<p class="ui-contentMarginTop">阶段信息</p>
				<cTable :columns="tendersTable" :dataList="tendersDataSource" bordered :isPage="false"></cTable>
				<p class="ui-contentMarginTop">阶段费用</p>
				<cTable :columns="fundsTable" :dataList="tendersDataCost" bordered :isPage="false"></cTable>
				<p class="ui-contentMarginTop">阶段资金凭证</p>
				<div class="ui-fileStyle">
					<div class="ui-common" v-if="tendersVoucher.length != 0">
						<div class="ui-common__item" v-for="(item,index) in tendersVoucher" :key="index">
							<a :href="item" target="_blank">
								<Icon icon="FilePdfOutlined" />{{ fileNameFun(item) }}
							</a>
						</div>
					</div>
					<div v-else>
						<a-empty />
					</div>
				</div>
				<p class="ui-contentMarginTop">阶段文件</p>
				<div class="ui-fileStyle">
					<div class="ui-common" v-if="tendersFiles.length != 0">
						<div class="ui-common__item" v-for="(item,index) in tendersFiles" :key="index">
							<a :href="item" target="_blank">
								<Icon icon="FilePdfOutlined" />{{ fileNameFun(item) }}
							</a>
						</div>
					</div>
					<div v-else>
						<a-empty />
					</div>
				</div>
			</div>

			<!-- 项目施工 -->
			<div id="build" class="ui-moduleBox steps-content" v-if="current >= 4">
				<div>
					<modalHeader title="项目施工"></modalHeader>
				</div>
				<p class="ui-contentMarginTop">阶段信息</p>
				<cTable :columns="applyTable" :dataList="applyDataSource" bordered :isPage="false"></cTable>
				<p class="ui-contentMarginTop">阶段费用</p>
				<cTable :columns="constructTable" :dataList="applyDataCost" bordered :isPage="false"></cTable>
				<p class="ui-contentMarginTop">阶段资金凭证</p>
				<div class="ui-fileStyle">
					<div class="ui-common" v-if="applyVoucher.length != 0">
						<div class="ui-common__item" v-for="(item,index) in applyVoucher" :key="index">
							<a :href="item" target="_blank">
								<Icon icon="FilePdfOutlined" />{{ fileNameFun(item) }}
							</a>
						</div>
					</div>
					<div v-else>
						<a-empty />
					</div>
				</div>
				<p class="ui-contentMarginTop">阶段文件</p>
				<div class="ui-fileStyle">
					<div class="ui-common" v-if="applyFiles.length != 0">
						<div class="ui-common__item" v-for="(item,index) in applyFiles" :key="index">
							<a :href="item" target="_blank">
								<Icon icon="FilePdfOutlined" />{{ fileNameFun(item) }}
							</a>
						</div>
					</div>
					<div v-else>
						<a-empty />
					</div>
				</div>
			</div>
			<!-- 竣工验收 -->
			<div id="finish" class="ui-moduleBox steps-content" style="margin-bottom: 30px;" v-if="current >= 5">
				<div>
					<modalHeader title="竣工验收"></modalHeader>
				</div>
				<p class="ui-contentMarginTop">阶段信息</p>
				<cTable :columns="finishTable" :dataList="finishDataSource" bordered :isPage="false"></cTable>
				<p class="ui-contentMarginTop">阶段费用</p>
				<cTable :columns="finishPriceTable" :dataList="finishDataCost" bordered :isPage="false"></cTable>
				<p class="ui-contentMarginTop">阶段资金凭证</p>
				<div class="ui-fileStyle">
					<div class="ui-common" v-if="finishVoucher.length != 0">
						<div class="ui-common__item" v-for="(item,index) in finishVoucher" :key="index">
							<a :href="item" target="_blank">
								<Icon icon="FilePdfOutlined" />{{ fileNameFun(item) }}
							</a>
						</div>
					</div>
					<div v-else>
						<a-empty />
					</div>
				</div>
				<p class="ui-contentMarginTop">阶段文件</p>
				<div class="ui-fileStyle">
					<div class="ui-common" v-if="finishFiles.length != 0">
						<div class="ui-common__item" v-for="(item,index) in finishFiles" :key="index">
							<a :href="item" target="_blank">
								<Icon icon="FilePdfOutlined" />{{ fileNameFun(item) }}
							</a>
						</div>
					</div>
					<div v-else>
						<a-empty />
					</div>
				</div>
			</div>
			<!-- <div class="ui-loaddownBtn">
				<a-button type="primary" size="large" @click="downLoadFile">下载文件</a-button>
			</div> -->
		</div>
		</a-spin>
	</div>
</template>

<script>
	import {
		postProjectBasic,
		postSaveStage,
		getProjectDetail,getProjectFile,downloadProjectFile
	} from '@/service/modules/project.js'
	import cTable from '@/components/cTable/index.vue';
	import filesUpload from '@/components/upload/filesUpload.vue';
	import {
		Icon
	} from '@/components/icon/icon.js';
	import {
		message
	} from 'ant-design-vue';
	import modalHeader from '@/components/modalHeader/index.vue';
	// const dataJson = require('@/views/project/projectData.json')
	import projectData from '@/views/project/projectData.js'
	export default {
		components: {
			Icon,
			cTable,
			modalHeader
			// selectModal,
			// saveModal
		},
		data() {
			return {
				loading: false,
				current: 0,
				// dataJson: dataJson,
				// formUseLand: projectData.formUseLand,
				// completion: projectData.completion,
				// construction: projectData.construction,
				// formApproval: projectData.formApproval,
				// inviteTenders: projectData.inviteTenders,
				projectInfo: {},
				stageInfos: {},
				projectStage: 0,
				projectId: null,
				auditAllCost:0,//三通一平审计金额
				steps: [{
						title: '基本信息',
						key: 'addInfo',
						form: 'formProjectInfoRef'
					},
					{
						title: '建设用地储备',
						key: 'land',
						form: 'formUseLandRef'
					},
					{
						title: '项目立项',
						key: 'confirm',
						form: 'formApprovalRef'
					},
					{
						title: '项目招标',
						key: 'inviteBids',
						form: 'inviteTendersRef'
					},
					{
						title: '项目施工',
						key: 'construction',
						form: 'constructionRef'
					},
					{
						title: '竣工验收',
						key: 'finish',
						form: 'completionRef'
					}
				],
				// 资金总览表，阶段费用表，项目立项（阶段费用）
				fundsTable: [{
						title: '资金类型',
						dataIndex: 'fundType',
						key: 'fundType'
					},
					{
						title: '合同金额',
						dataIndex: 'contractPrice',
						key: 'contractPrice'
					},
					{
						title: '已拨付数',
						dataIndex: '',
						key: 'delivered',
						children: [{
								title: '上级专项资金',
								dataIndex: 'specialPrice',
								key: 'specialPrice'
							},
							{
								title: '县国资公司',
								dataIndex: 'companyPrice',
								key: 'companyPrice'
							},
							{
								title: '县本级财政资金',
								dataIndex: 'financialPrice',
								key: 'financialPrice'
							},
							{
								title: '其他来源资金',
								dataIndex: 'orderPrice',
								key: 'orderPrice'
							},
							{
								title: '小计',
								dataIndex: 'subtotal',
								key: 'subtotal'
							},
						]
					},
					{
						title: '待拨付金额',
						key: 'waitDelivered',
						dataIndex: 'waitDelivered'
					}
				],
				fundsDataSource: [], //资金总览table
				fundsDataCertificate: [],//办证时间
				// 建设用地储备表
				landTable: [{
						title: '用地来源',
						dataIndex: 'landFrom',
						key: 'landFrom'
					},
					{
						title: '林地性质',
						dataIndex: 'forestryNature',
						key: 'forestryNature'
					},
					{
						title: '取得方式',
						dataIndex: 'landMethods',
						key: 'landMethods'
					},
					{
						title: '乡镇是否是项目实施单位',
						dataIndex: 'isApply',
						key: 'isApply'
					},
					{
						title: '是否前置办理不动产权登记证',
						key: 'isProperty',
						dataIndex: 'isProperty'
					},
					{
						title: '是否有三通一平支出',
						key: 'payCheckSpeed',
						dataIndex: 'payCheckSpeed'
					},
					{
						title: '是否有三通一平审计',
						key: 'payCheckAudit',
						dataIndex: 'payCheckAudit'
					},
					{
						title: '是否有污染状况调查',
						key: 'isSurvey',
						dataIndex: 'isSurvey'
					}
				],
				landDataSource: [], //建设用地储备表table
				stagePriceDataSource: [], // 阶段费用表table
				// 阶段资金凭证
				fundVoucher: [],
				// 阶段文件
				stageFiles: [],
				// 阶段信息表
				approvalTable: [{
						title: '是否重大重点项目',
						dataIndex: 'isImportant',
						key: 'isImportant'
					},
					{
						title: '是否列入投资计划',
						dataIndex: 'isPlan',
						key: 'isPlan'
					},
					{
						title: '投资概算',
						dataIndex: 'budget',
						key: 'budget'
					},
					{
						title: '资金来源',
						children: [{
								title: '上级专项资金',
								dataIndex: 'specialPrice',
								key: 'specialPrice'
							},
							{
								title: '县国资公司',
								dataIndex: 'companyPrice',
								key: 'companyPrice'
							},
							{
								title: '县本级财政资金',
								dataIndex: 'financialPrice',
								key: 'financialPrice'
							},
							{
								title: '其他来源资金',
								dataIndex: 'orderPrice',
								key: 'orderPrice'
							},
						]
					},
					{
						title: '是否已立项',
						dataIndex: 'reviewStatus',
						key: 'reviewStatus'
					},
					{
						title: '有无用地选址意见书',
						dataIndex: 'isApprovalBook',
						key: 'isApprovalBook'
					},
					{
						title: '有无可研批复',
						dataIndex: 'isFeasibility',
						key: 'isFeasibility'
					},
					{
						title: '有无风险稳定评估',
						dataIndex: 'isRisk',
						key: 'isRisk'
					},
					{
						title: '有无初设批复',
						dataIndex: 'isApproval',
						key: 'isApproval'
					},
					{
						title: '有无环评',
						dataIndex: 'isEstimate',
						key: 'isEstimate'
					},
					{
						title: '有无水保方案',
						dataIndex: 'isProtect',
						key: 'isProtect'
					},
					{
						title: '有无防洪评价',
						dataIndex: 'isFlood',
						key: 'isFlood'
					},
					{
						title: '有无节能审批',
						dataIndex: 'isPower',
						key: 'isPower'
					},
					{
						title: '有无消防审批',
						dataIndex: 'isFire',
						key: 'isFire'
					},
					{
						title: '有无人防审批',
						dataIndex: 'isDefence',
						key: 'isDefence'
					},
				],
				// 阶段信息表table
				approvalDataSource: [],
				// 项目立项（阶段费用）
				approvalDataCost: [],
				// 阶段资金凭证
				approvalVoucher: [],
				approvalFiles: [],
				// 项目招标（阶段信息）
				tendersTable: [{
						title: '是否EPC项目',
						dataIndex: 'epc',
						key: 'epc'
					},
					{
						title: '中标公司名称',
						dataIndex: 'companyName',
						key: 'companyName'
					},
					{
						title: '招标方式',
						dataIndex: 'biddingWay',
						key: 'biddingWay'
					},
					{
						title: '有无监理',
						dataIndex: 'watcher',
						key: 'watcher'
					},
					{
						title: '监理公司名称',
						dataIndex: 'watcherCompany',
						key: 'watcherCompany'
					},
					{
						title: '有无跟踪审计',
						dataIndex: 'flowable',
						key: 'flowable'
					},
					{
						title: '跟踪审计公司',
						dataIndex: 'flowableCompany',
						key: 'flowableCompany'
					},
					{
						title: '有无过程咨询',
						dataIndex: 'hasSaf',
						key: 'hasSaf'
					},
					{
						title: '全过程咨询公司',
						dataIndex: 'safCompany',
						key: 'safCompany'
					}
				],
				tendersDataSource: [],
				tendersDataCost: [],
				tendersVoucher: [],
				tendersFiles: [],
				// 项目施工（阶段信息）
				applyTable: [{
						title: '有无施工许可',
						dataIndex: 'permit',
						key: 'permit'
					},
					{
						title: '有无项目变更',
						dataIndex: 'change',
						key: 'change'
					},
					{
						title: '有无设备询价',
						dataIndex: 'inquiry',
						key: 'inquiry'
					},
					{
						title: '设备询价公司',
						dataIndex: 'inquiryCompany',
						key: 'inquiryCompany'
					},
					{
						title: '工程进度',
						dataIndex: 'schedule',
						key: 'schedule'
					},
					{
						title: '核定付款比例',
						dataIndex: 'payPercent',
						key: 'payPercent'
					},
					{
						title: '有无审计',
						dataIndex: 'audit',
						key: 'audit'
					},
					{
						title: '审计公司',
						dataIndex: 'auditCompany',
						key: 'auditCompany'
					}
				],
				applyDataSource: [],
				applyDataCost: [],
				applyVoucher: [],
				applyFiles: [],
				// 竣工验收（阶段信息）
				finishTable: [{
						title: '有无避雷检查',
						dataIndex: 'isHasLpi',
						key: 'isHasLpi'
					},
					{
						title: '有无消防验收',
						dataIndex: 'isHasFire',
						key: 'isHasFire'
					},
					{
						title: '有无建设规划验收',
						dataIndex: 'isHasBuild',
						key: 'isHasBuild'
					},
					{
						title: '有无环评验收',
						dataIndex: 'isHasEia',
						key: 'isHasEia'
					},
					{
						title: '有无水保验收',
						dataIndex: 'isHasKeep',
						key: 'isHasKeep'
					},
					{
						title: '有无竣工验收备案',
						dataIndex: 'isHasFiling',
						key: 'isHasFiling'
					},
					{
						title: '财务决算金额',
						dataIndex: 'accounts',
						key: 'accounts'
					},
				],
				finishDataSource: [],
				constructTable: [{
						title: '资金类型',
						dataIndex: 'fundType',
						key: 'fundType'
					},
					{
						title: '合同金额',
						dataIndex: 'contractPrice',
						key: 'contractPrice'
					},
					{
						title: '审计金额',
						dataIndex: 'audit',
						key: 'audit'
					},
					{
						title: '增量金额',
						dataIndex: 'addAmountPrice',
						key: 'addAmountPrice'
					},
					{
						title: '已拨付数',
						dataIndex: '',
						key: 'delivered',
						children: [{
								title: '上级专项资金',
								dataIndex: 'specialPrice',
								key: 'specialPrice'
							},
							{
								title: '县国资公司',
								dataIndex: 'companyPrice',
								key: 'companyPrice'
							},
							{
								title: '县本级财政资金',
								dataIndex: 'financialPrice',
								key: 'financialPrice'
							},
							{
								title: '其他来源资金',
								dataIndex: 'orderPrice',
								key: 'orderPrice'
							},
							{
								title: '小计',
								dataIndex: 'subtotal',
								key: 'subtotal'
							},
						]
					},
					{
						title: '待拨付金额',
						key: 'waitDelivered',
						dataIndex: 'waitDelivered'
					}
				],
				finishPriceTable: [{
						title: '费用类别',
						dataIndex: 'costType',
						key: 'costType'
					},
					{
						title: '费用名称',
						dataIndex: 'costName',
						key: 'costName'
					},
					{
						title: '中介公司',
						dataIndex: 'company',
						key: 'company'
					},
					{
						title: '合同金额',
						dataIndex: 'contractPrice',
						key: 'cost'
					},
					{
						title: '已拨付数',
						children: [{
								title: '上级专项资金',
								dataIndex: 'specialPrice',
								key: 'specialPrice'
							},
							{
								title: '县国资公司',
								dataIndex: 'companyPrice',
								key: 'companyPrice'
							},
							{
								title: '县本级财政资金',
								dataIndex: 'financialPrice',
								key: 'financialPrice'
							},
							{
								title: '其他来源资金',
								dataIndex: 'orderPrice',
								key: 'orderPrice'
							},
						]
					},
					{
						title: '待拨付金额',
						dataIndex: 'waitAmount',
						key: 'waitAmount'
					}
				],
				finishDataCost: [],
				finishVoucher: [],
				finishFiles: [],
				fundsDataCertificateTable:[
					{
						title:'证书名称',
						dataIndex:'certificate',
						key:'certificate'
					},
					{
						title:'使用林地审核同意书',
						dataIndex:'buildLandNatureAuditTime',
						key:'buildLandNatureAuditTime'
					},
					{
						title:'用地规划许可证',
						dataIndex:'buildLandRegisterPermitTime',
						key:'buildLandRegisterPermitTime'
					},
					{
						title:'不动产权登记证',
						dataIndex:'buildLandRegisterRegisterTime',
						key:'buildLandRegisterRegisterTime'
					},
					{
						title:'立项批复文件',
						dataIndex:'projectApprovalReviewTime',
						key:'projectApprovalReviewTime'
					},
					{
						title:'可研批复',
						dataIndex:'projectApprovalFeasibilityTime',
						key:'projectApprovalFeasibilityTime'
					},
					{
						title:'工程规划许可证',
						dataIndex:'projectApprovalPermitProjectTime',
						key:'projectApprovalPermitProjectTime'
					},
					{
						title:'社会风险稳定评估备案表',
						dataIndex:'projectApprovalRiskTime',
						key:'projectApprovalRiskTime'
					},
					{
						title:'初步设计批复',
						dataIndex:'projectApprovalDesignTime',
						key:'projectApprovalDesignTime'
					},
					{
						title:'环评批复',
						dataIndex:'projectApprovalEnvApprovalTime',
						key:'projectApprovalEnvApprovalTime'
					},
					{
						title:'节能审批文件',
						dataIndex:'projectApprovalEcTime',
						key:'projectApprovalEcTime'
					},
					{
						title:'消防审批意见文书',
						dataIndex:'projectApprovalFireTime',
						key:'projectApprovalFireTime'
					},
					{
						title:'人防审批意见文书',
						dataIndex:'projectApprovalPadTime',
						key:'projectApprovalPadTime'
					},
					{
						title:'施工许可证',
						dataIndex:'constructionPermitBuildTime',
						key:'constructionPermitBuildTime'
					},
					{
						title:'开工报告',
						dataIndex:'constructionPermitStartReportTime',
						key:'constructionPermitStartReportTime'
					},
					{
						title:'增量批复',
						dataIndex:'constructionChangeResponseTime',
						key:'constructionChangeResponseTime'
					},
					{
						title:'同意询价批复',
						dataIndex:'constructionInquiryTime',
						key:'constructionInquiryTime'
					},
				]
			}
		},
		created() {
			this.projectId = this.$route.query.id
			// this.current = this.projectStage ? Number(this.projectStage) : 0;
			this.getDetailFun(this.projectId)
		},
		mounted() {
			// this.init()
		},
		computed:{
			feasibilityPrice(){//聘请可研编制合同金额
				// let {projectApprovalSpeedFeasibilityCost} = this.stageInfos;
				let feasibilityPrice = this.stageInfos?.projectApprovalSpeedFeasibilityCost ? this.stageInfos?.projectApprovalSpeedFeasibilityCost : 0;
				return feasibilityPrice;
			},
			feasibilityPayment(){//聘请可研编制付款记录
				// let {projectApprovalSpeedFeasibilityPayment} = this.stageInfos;
				let feasibilityPayment = this.stageInfos?.projectApprovalSpeedFeasibilityPayment ? JSON.parse(this.stageInfos?.projectApprovalSpeedFeasibilityPayment) : [];
				return feasibilityPayment;
			},
			designerPrice(){//聘请施工图设计合同金额
				// let {projectApprovalSpeedDesignerCost} = this.stageInfos;
				let designerPrice = this.stageInfos?.projectApprovalSpeedDesignerCost ? this.stageInfos?.projectApprovalSpeedDesignerCost : 0;
				return designerPrice;
			},
			designerPayment(){//聘请施工图设计付款记录
				// let {projectApprovalSpeedDesignerPayment} = this.stageInfos;
				let designerPayment = this.stageInfos?.projectApprovalSpeedDesignerPayment ? JSON.parse(this.stageInfos?.projectApprovalSpeedDesignerPayment) : [];
				return designerPayment;
			},
			checkerPrice(){//聘请图审合同金额
				// let {projectApprovalSpeedCheckerCost} = this.stageInfos;
				let checkerPrice = this.stageInfos?.projectApprovalSpeedCheckerCost ? this.stageInfos?.projectApprovalSpeedCheckerCost : 0;
				return checkerPrice;
			},
			checkerPayment(){//聘请图审付款记录
				// let {projectApprovalSpeedCheckerPayment} = this.stageInfos;
				let checkerPayment = this.stageInfos?.projectApprovalSpeedCheckerPayment ? JSON.parse(this.stageInfos?.projectApprovalSpeedCheckerPayment) : [];
				return checkerPayment;
			},
			surveyorPrice(){//测绘合同金额
				// let {projectApprovalSpeedSurveyorCost} = this.stageInfos;
				let surveyorPrice = this.stageInfos?.projectApprovalSpeedSurveyorCost ? this.stageInfos?.projectApprovalSpeedSurveyorCost : 0;
				return surveyorPrice;
			},
			surveyorPayment(){//测绘付款记录
				// let {projectApprovalSpeedSurveyorPayment} = this.stageInfos;
				let surveyorPayment = this.stageInfos?.projectApprovalSpeedSurveyorPayment ? JSON.parse(this.stageInfos?.projectApprovalSpeedSurveyorPayment) : [];
				return surveyorPayment;
			},
			geologicalPrice(){//地勘合同金额
				// let {projectApprovalSpeedGeologicalCost} = this.stageInfos;
				let geologicalPrice = this.stageInfos?.projectApprovalSpeedGeologicalCost ? this.stageInfos?.projectApprovalSpeedGeologicalCost : 0;
				return geologicalPrice;
			},
			geologicalPayment(){//地勘付款记录
				// let {projectApprovalSpeedGeologicalPayment} = this.stageInfos;
				let geologicalPayment = this.stageInfos?.projectApprovalSpeedGeologicalPayment ? JSON.parse(this.stageInfos?.projectApprovalSpeedGeologicalPayment) : [];
				return geologicalPayment;
			},
			budgetingPrice(){//预算编制合同金额
				// let {projectApprovalSpeedBudgetingCost} = this.stageInfos;
				let budgetingPrice = this.stageInfos?.projectApprovalSpeedBudgetingCost ? this.stageInfos?.projectApprovalSpeedBudgetingCost : 0;
				return budgetingPrice;
			},
			budgetingPayment(){//预算编制付款记录
				// let {projectApprovalSpeedBudgetingPayment} = this.stageInfos;
				let budgetingPayment = this.stageInfos?.projectApprovalSpeedBudgetingPayment ? JSON.parse(this.stageInfos?.projectApprovalSpeedBudgetingPayment) : [];
				return budgetingPayment;
			},
			budgetPrice(){//预算评审
				// let {projectApprovalSpeedBudgetCost} = this.stageInfos;
				let budgetPrice = this.stageInfos?.projectApprovalSpeedBudgetCost ? this.stageInfos?.projectApprovalSpeedBudgetCost : 0;
				return budgetPrice;
			},
			budgetPayment(){//预算评审
				// let {projectApprovalSpeedBudgetPayment} = this.stageInfos;
				let budgetPayment = this.stageInfos?.projectApprovalSpeedBudgetPayment ? JSON.parse(this.stageInfos?.projectApprovalSpeedBudgetPayment) : [];
				return budgetPayment;
			},
			agentPrice(){//招标代理
 				// let {projectApprovalSpeedAgentCost} = this.stageInfos;
				let agentPrice = this.stageInfos?.projectApprovalSpeedAgentCost ? this.stageInfos?.projectApprovalSpeedAgentCost : 0;
				return agentPrice;
			},
			agentPayment(){//招标代理
 				// let {projectApprovalSpeedAgentPayment} = this.stageInfos;
				let agentPayment = this.stageInfos?.projectApprovalSpeedAgentPayment ? JSON.parse(this.stageInfos?.projectApprovalSpeedAgentPayment) : [];
				return agentPayment;
			},
			auditProjectPay(){//工程款是否有审计金额
				let {constructionPayment} = this.stageInfos;
				let auditProjectPayList = constructionPayment ? JSON.parse(constructionPayment) : [];
				let flag = false;
				let auditProjectPay = 0;
				if(auditProjectPayList.length != 0){
					auditProjectPayList.forEach((item)=>{
						// 有审计金额
						if(item.auditCost){
							auditProjectPay += item.auditCost;
							flag = true;
						}
					})
				}
				// 无审计金额，返回0
				if(!flag){
					return 0;
				}
				return auditProjectPay;//返回审计金额
			}
		},
		methods: {
			rowClassName(record,index){
				if(this.stageInfos?.buildLandGainType==='0'||this.stageInfos?.buildLandGainType==='1'||this.stageInfos?.buildLandGainType==='3'){
                          if(record.fundType==='征拆资金'||record.fundType==='土地报批费'){
							return 'ui-gray'
						  }
				}
			},
			async getDetailFun(id) {
				try {
					this.loading = true;
					let ret = await getProjectDetail({
						id
					})
					this.loading = false;
					if (ret.code === 200) {
						this.projectStage = ret.data.stage;
						this.current = this.projectStage;
						this.projectInfo = ret.data
						this.stageInfos = this.projectInfo.stageInfos
						setTimeout(() => {
							this.init()
						}, 1000)
					}
				} catch (e) {
					this.loading = false;
					//TODO handle the exception
				}
			},
			contractAmount(mount) { // 合同金额
				if (mount) {
					return mount
				} else {
					return 0
				}
			},
      formatNumber(num, decimal = 2) {
        return Number.isInteger(num) ? num : Number(num.toFixed(decimal));
      },
			// 下载项目文件
			async downLoadFile(){
				try{
					let ret = await downloadProjectFile({
						projectId: this.projectId
					})
					if(ret.code == 200){
						this.$message.success('导出任务创建成功，请前往【系统-任务中心-导出任务列表】查看进度详情并下载');
					}
				} catch(e){
				}
			},
			speedMount(totalCost, payment) { // 三通一平审计 合同金额
				let jsPayment = (typeof payment === 'string') ? JSON.parse(payment) : payment
				let auditCost = 0;
				if (jsPayment) {
					jsPayment.forEach(item => {
						auditCost += (item.auditCost || 0)
					})
					if (auditCost) {
						return auditCost
					}
				}
				return totalCost || 0;
			},
			speedPayment(payment, source) { // 三通一平 或其它四项已拨
				let jsPayment = (typeof payment === 'string') ? JSON.parse(payment) : payment
				let specialCost = 0
				let companyCost = 0
				let sourceCost = 0
				let otherCost = 0
				if (jsPayment) {
					jsPayment.forEach(item => {
						if(Array.isArray(item)) {
							item.forEach(itemChild => {
								switch (itemChild.source) {
									case 0:
										specialCost += itemChild.amount
										break;
									case 1:
										companyCost += itemChild.amount
										break;
									case 2:
										sourceCost += itemChild.amount
										break;
									case 3:
										otherCost += itemChild.amount
										break;
								}
							})
						} else {
							switch (item.source) {
								case 0:
									if(item.amount)
									specialCost += item.amount
									break;
								case 1:
									if(item.amount)
									companyCost += item.amount
									break;
								case 2:
									if(item.amount)
									sourceCost += item.amount
									break;
								case 3:
									if(item.amount)
									otherCost += item.amount
									break;
							}
						}
					})
				}
				if (source == 0) return specialCost
				if (source == 1) return companyCost
				if (source == 2) return sourceCost
				if (source == 3) return otherCost
			},
			/**
			 * @param {paymemt} 数组
			 * @param {flag} 是否是三通一平支出
			 */
			totalSpeedPayment(payment,flag) { // 小计
				let jsPayment = (typeof payment === 'string') ? JSON.parse(payment) : payment
				let totalCost = 0
				if (jsPayment) {
					jsPayment.forEach(item => {
						if(Array.isArray(item)) {
							item.forEach(itemChild => {
								totalCost += (item.amount || 0)
							})
						}else{
							if(flag){
								item.payment.forEach(itemChild => {
									totalCost += (itemChild.amount || 0);
								})
							}else{
								totalCost += (item.amount || 0)
							}
						}
					})
					return totalCost
				}
				return 0
			},
			speedCount(payment){//三通一平支出，合同金额
				let jsPayment = (typeof payment === 'string') ? JSON.parse(payment) : payment
				let allPayment = 0;
				if(jsPayment){
					jsPayment.forEach(item=>{
						allPayment += (item.cost || 0);
					})
				}
				if(allPayment){
					return allPayment;
				}
				return 0;
			},
			speedCountPayment(payment,source){ //三通一平，一波付款计算
				let jsPayment = (typeof payment === 'string') ? JSON.parse(payment) : payment
				let specialCost = 0
				let companyCost = 0
				let sourceCost = 0
				let otherCost = 0
				if(jsPayment){
					jsPayment.forEach(item=>{
						item.payment.forEach(devItem=>{
							switch (devItem.source) {
								case 0:
									specialCost += devItem.amount
									break;
								case 1:
									companyCost += devItem.amount
									break;
								case 2:
									sourceCost += devItem.amount
									break;
								case 3:
									otherCost += devItem.amount
									break;
							}
						})
					})
				}
				if (source == 0) return specialCost
				if (source == 1) return companyCost
				if (source == 2) return sourceCost
				if (source == 3) return otherCost
			},
			speedCountAuditCost(payment){//三通一平 计算审计金额,return true or false
				let jsPayment = (typeof payment === 'string') ? JSON.parse(payment) : payment;
				let flag = false;//是否有审计金额
				let auditAll = 0;
				if(jsPayment){
					jsPayment.forEach(item=>{
						let flagItem = false;//单个list是否有审计金额
						item.payment.forEach(devItem=>{
							if(devItem.type == 1 && devItem.auditCost){
								flag = true;
								flagItem = true;
								auditAll += devItem.auditCost;
							}
						})
						if(!flagItem){
							auditAll += item.cost;
						}
					})
				}
				this.auditAllCost = auditAll;
				return flag;
			},
			fundVoucherFun(str, fileClass) { // 处理项目文件
				let voucherArr = []
				let fileArr = []
				let tempObj = this.stageInfos
				// console.log(tempObj, 111111)
				for (var i in tempObj) {
					// if(i.includes('File') && i.includes(str)) {
					if (tempObj[i] && tempObj[i].includes('http') && i.includes(str)) {
						if (tempObj[i].includes('[')) {
							let jsPayment = JSON.parse(tempObj[i])
							jsPayment.forEach(item => {
								if (str === 'completion' && item.payment) {
									item.payment.forEach(subItem => {
										if (subItem.ticket.indexOf(',')) {
											subItem.ticket.split(',').forEach(ticketItem => {
												voucherArr.push(ticketItem);
											})
										} else if (subItem.ticket) {
											voucherArr.push(subItem.ticket)
										}
										if (subItem.voucher.indexOf(',')) {
											subItem.voucher.split(',').forEach(voucherItem => {
												voucherArr.push(voucherItem);
											})
										} else if (subItem.voucher) {
											voucherArr.push(subItem.voucher)
										}
									})
									if(item.contract){
										item.contract.split(',').forEach((element)=>{
											fileArr.push(element);
										})
									}
									if(item.procedures){
										item.procedures.split(',').forEach((element)=>{
											fileArr.push(element);
										})
									}
									if(item.summary){
										item.summary.split(',').forEach((element)=>{
											fileArr.push(element);
										})
									}
								} else {
									for (let j in item) {
										if (j === 'applyFile' || j === 'ticket' || j === 'voucher' || j ==
											'scheduleFile' || j === 'auditReport' || j === 'progress' || j == 'audit') {
												if (item[j].indexOf(',') !== -1) {
													let itemFileArr = item[j].split(',');
													itemFileArr.forEach(subItem => {
														if(subItem != ''){
															voucherArr.push(subItem);
														}
													})
												} else {
													if(item[j] != ''){
														voucherArr.push(item[j])
													}
												}
										}else if(j === 'contract' || j === 'notice' || j === 'summary'){
											if(item[j].indexOf(',') !== -1){ // 'xxx,xxx'
												let list = item[j].split(',');
												list.forEach((element)=>{
													if(element != ''){
														fileArr.push(element);
													}
												})
											}
											if(Array.isArray(item[j])){//[xx,xx]
												item[j].forEach(childrenItem => {
													fileArr.push(childrenItem);
												})
											}else{ // xxx
												fileArr.push(item[j]);
											}
										}else if(j === 'payment'){
											if(item[j] && item[j].length){
												item[j].forEach((item)=>{
													if(item.ticket && item.ticket.length){
														if(Array.isArray(item.ticket)){
															item.ticket.forEach(itemfile =>{
																voucherArr.push(itemfile);
															})
														}else{
															let list = item.ticket.split(',');
															list.forEach((itemfile)=>{
																voucherArr.push(itemfile)
															})
														}
													}
													if(item.voucher && item.voucher.length){
														if(Array.isArray(item.voucher)){
															item.voucher.forEach(itemfile =>{
																voucherArr.push(itemfile);
															})
														}else{
															let list = item.voucher.split(',');
															list.forEach((itemfile)=>{
																voucherArr.push(itemfile)
															})
														}
													}
													if(item.applyFile && item.applyFile.length){
														if(Array.isArray(item.applyFile)){
															item.applyFile.forEach(itemfile =>{
																voucherArr.push(itemfile);
															})
														}else{
															let list = item.applyFile.split(',');
															list.forEach((itemfile)=>{
																voucherArr.push(itemfile)
															})
														}
													}
													if(item.auditCost && item.auditCost.length){
														if(Array.isArray(item.auditCost)){
															item.auditCost.forEach(itemfile =>{
																voucherArr.push(itemfile);
															})
														}else{
															let list = item.auditCost.split(',');
															list.forEach((itemfile)=>{
																voucherArr.push(itemfile)
															})
														}
													}
													if(item.scheduleFile && item.scheduleFile.length){
														if(Array.isArray(item.scheduleFile)){
															item.scheduleFile.forEach(itemfile =>{
																voucherArr.push(itemfile);
															})
														}else{
															let list = item.scheduleFile.split(',');
															list.forEach((itemfile)=>{
																voucherArr.push(itemfile)
															})
														}
													}
												})
											}
										}
									}
								}
							})
						} else if (i.includes('PaymentFile') || i.includes('Ticket')){//支付文件或者发票
							let temp = tempObj[i].split(',');
							temp.forEach(item=>{
								voucherArr.push(item);
							})
						} else if (tempObj[i].includes(',')){
							let temp = tempObj[i].split(',');
							temp.forEach(item=>{
								fileArr.push(item);
							})
						}else {
							fileArr.push(tempObj[i])
						}
					}
				}
				return fileClass === 0 ? voucherArr : fileArr
			},
			fileNameFun(item) { // 截取文件名
				let preIndex = item.lastIndexOf('/')
				let nextIndex = item.lastIndexOf('.')
				let urlName = item.slice(preIndex + 1, nextIndex)
				return urlName
			},
			amountSummary(arr, key) { // 列表合计金额
				let amount = 0
				if(!arr) return amount;
				arr.forEach(itemArr => {
					for (let i in itemArr) {
						if (i === key) {
							if(this.stageInfos?.buildLandGainType==='0'||this.stageInfos?.buildLandGainType==='1'||this.stageInfos?.buildLandGainType==='3'){
								if((itemArr.fundType==='征拆资金'||itemArr.fundType==='土地报批费')){
									return
								}
							}
							amount += (Number(itemArr[i]) || 0)
						}
					}
				})
        if (!Number.parseInt(amount)) {
          // 非整数
          amount = parseFloat(amount.toFixed(2));
        }
				return amount
			},
			completionOtherFun(arrItem,flag) { // 竣工验收 第三方费用
				// let currArr = {}
				let list = [];
				let typeArr = ['避雷检查', '消防验收', '建设规划验收', '环评验收','水保验收','交、竣工验收', '竣工备案', '其他'];
				if (arrItem&&!flag) {
					let jsOther = JSON.parse(arrItem)
					jsOther.forEach(item => {
						if(item.cost > 0){
							list.push({
								costType: typeArr[Number(item.costType)],
								costName: item.costName,
								company: item.company,
								contractPrice: item.cost || 0,
								specialPrice: this.speedPayment(item.payment, 0),
								companyPrice: this.speedPayment(item.payment, 1),
								financialPrice: this.speedPayment(item.payment, 2),
								orderPrice: this.speedPayment(item.payment, 3),
								// subtotal: this.totalSpeedPayment(item.payment),
								waitAmount: (item?.cost || 0) - this.totalSpeedPayment(item.payment)
							})
						}
					})
					let tempTotal = {
							costType: '合计',
							contractPrice: 0,
							specialPrice: 0,
							companyPrice: 0,
							financialPrice: 0,
							orderPrice: 0,
							waitAmount: 0,
						}
					this.finishDataCost.push(...list);
					this.finishDataCost.push(tempTotal)
				}else{
					if(!arrItem) return;
					let jsOther = JSON.parse(arrItem)
					jsOther.forEach(item => {
						if(item.cost > 0){
							list.push({
								fundType: item.costName,
								contractPrice: item.cost || 0,
								specialPrice: this.speedPayment(item.payment, 0),
								companyPrice: this.speedPayment(item.payment, 1),
								financialPrice: this.speedPayment(item.payment, 2),
								orderPrice: this.speedPayment(item.payment, 3),
								subtotal: this.totalSpeedPayment(item.payment),
								waitDelivered: (item?.cost || 0) - this.totalSpeedPayment(item.payment)
							})
						}
					})
					return list;
				}
			},
			approvalSpeedOtherFun(arrItem) { // 项目立项中介费用
				let jsOther;
				let list = [];
				if(arrItem){
					jsOther = JSON.parse(arrItem);
				}
				let fundsLen = this.fundsDataSource.length
				if (jsOther && jsOther[0].service) {
					jsOther.forEach(item => {
						let approvalCost = {
							fundType: item.service,
							contractPrice: item.cost,
							specialPrice: this.speedPayment(item.payment, 0),
							companyPrice: this.speedPayment(item.payment, 1),
							financialPrice: this.speedPayment(item.payment, 2),
							orderPrice: this.speedPayment(item.payment, 3),
							subtotal: this.totalSpeedPayment(item.payment),
							waitDelivered: (item?.cost || 0) - this.totalSpeedPayment(item.payment)
						}
						list.push(approvalCost);
						// this.approvalDataCost.push(approvalCost)
						// this.fundsDataSource.splice(fundsLen - 1, 0, approvalCost)
					})
					let tempTotal = {
							fundType: '合计',
							contractPrice: 0,
							specialPrice: 0,
							companyPrice: 0,
							financialPrice: 0,
							orderPrice: 0,
							subtotal: 0,
							waitDelivered: 0,
						}
					this.approvalDataCost.push(...list);
					this.approvalDataCost.push(tempTotal);
					return list;
				}
			},
			initcompletionOtherFun(arrItem){//初始化资金总览的中介费用
				let list = [];
				if (arrItem && arrItem[0].company && arrItem[0].cost) {
					let jsOther = JSON.parse(arrItem)
					jsOther&&jsOther.forEach(item => {
						list.push({
							fundType: item.costName,
							contractPrice: item.cost || 0,
							specialPrice: this.speedPayment(item.payment, 0),
							companyPrice: this.speedPayment(item.payment, 1),
							financialPrice: this.speedPayment(item.payment, 2),
							orderPrice: this.speedPayment(item.payment, 3),
							subtotal: this.totalSpeedPayment(item.payment),
							waitDelivered: (item?.cost || 0) - this.totalSpeedPayment(item.payment)
						})
					})
				}
				return list;
			},
			initFundsDateSource(){//初始化资金总览
				let fundsDataSource = [];
				if(this.contractAmount(this.stageInfos?.buildLandSourceTotalCost) > 0){
					fundsDataSource.push(
					{
						fundType: '征拆资金',
						contractPrice: this.contractAmount(this.stageInfos?.buildLandSourceTotalCost || 0),
						specialPrice: this.contractAmount(this.stageInfos?.buildLandSourceSpecialCost || 0),
						companyPrice: this.contractAmount(this.stageInfos?.buildLandSourceCompanyCost || 0),
						financialPrice: this.contractAmount(this.stageInfos?.buildLandSourceSourceCost || 0),
						orderPrice: this.contractAmount(this.stageInfos?.buildLandSourceOtherCost || 0),
						subtotal: this.contractAmount(this.stageInfos?.buildLandSourceTotalCost || 0),
						waitDelivered: 0,

					})
				}
				if(this.contractAmount(this.stageInfos?.buildLandNatureMapCost) > 0){
					fundsDataSource.push({
						fundType: '林地测绘费用',
						contractPrice: this.contractAmount(this.stageInfos?.buildLandNatureMapCost),
						specialPrice: this.contractAmount(this.stageInfos?.buildLandNatureMapSpecialCost),
						companyPrice: this.contractAmount(this.stageInfos?.buildLandNatureMapCompanyCost),
						financialPrice: this.contractAmount(this.stageInfos?.buildLandNatureMapSourceCost),
						orderPrice: this.contractAmount(this.stageInfos?.buildLandNatureMapOtherCost),
						subtotal: this.contractAmount(this.stageInfos?.buildLandNatureMapCost),
						waitDelivered: 0,
					})
				}
				if(this.contractAmount(this.stageInfos?.buildLandNatureApprovalCost) > 0){
					fundsDataSource.push({
						fundType: '林地报批费用',
						contractPrice: this.contractAmount(this.stageInfos?.buildLandNatureApprovalCost),
						specialPrice: this.contractAmount(this.stageInfos?.buildLandNatureApprovalSpecialCost),
						companyPrice: this.contractAmount(this.stageInfos?.buildLandNatureApprovalCompanyCost),
						financialPrice: this.contractAmount(this.stageInfos?.buildLandNatureApprovalSourceCost),
						orderPrice: this.contractAmount(this.stageInfos?.buildLandNatureApprovalOtherCost),
						subtotal: this.contractAmount(this.stageInfos?.buildLandNatureApprovalCost),
						waitDelivered: 0,
					})
				}
				if(this.contractAmount(this.stageInfos?.buildLandApprovalCost) > 0){
					fundsDataSource.push(
						{
						fundType: '土地报批费',
						contractPrice: this.contractAmount(this.stageInfos?.buildLandApprovalCost),
						specialPrice: this.stageInfos?.buildLandApprovalCostSource == 0 ? this.contractAmount(this
							.stageInfos?.buildLandApprovalCost) : 0,
						companyPrice: this.stageInfos?.buildLandApprovalCostSource == 1 ? this.contractAmount(this
							.stageInfos?.buildLandApprovalCost) : 0,
						financialPrice: this.stageInfos?.buildLandApprovalCostSource == 2 ? this.contractAmount(this
							.stageInfos?.buildLandApprovalCost) : 0,
						orderPrice: this.stageInfos?.buildLandApprovalCostSource == 3 ? this.contractAmount(this
							.stageInfos?.buildLandApprovalCost) : 0,
						subtotal: this.contractAmount(this.stageInfos?.buildLandApprovalCost || 0),
						waitDelivered: 0,

					})
				}
				if(this.contractAmount(this.stageInfos?.buildLandGainTotalCost) > 0){
					fundsDataSource.push({
						fundType: this.stageInfos?.buildLandGainType == 0 ? '土地出让金' : this.stageInfos?.buildLandGainType == 1 ? '有偿划拨支付资金' : '土地转让金' ,
						contractPrice: this.contractAmount(this.stageInfos?.buildLandGainTotalCost),
						specialPrice: this.contractAmount(this.stageInfos?.buildLandGainSpecialCost),
						companyPrice: this.contractAmount(this.stageInfos?.buildLandGainCompanyCost),
						financialPrice: this.contractAmount(this.stageInfos?.buildLandGainSourceCost),
						orderPrice: this.contractAmount(this.stageInfos?.buildLandGainOtherCost),
						subtotal: this.contractAmount(this.stageInfos?.buildLandGainTotalCost),
						waitDelivered: 0,

					})
				}
				if(this.contractAmount(this.stageInfos?.buildLandRegisterTotalCost) > 0){
					fundsDataSource.push(
					{
						fundType: '办证费用',
						contractPrice: this.contractAmount(this.stageInfos?.buildLandRegisterTotalCost),
						specialPrice: this.contractAmount(this.stageInfos?.buildLandRegisterSpecialCost),
						companyPrice: this.contractAmount(this.stageInfos?.buildLandRegisterCompanyCost),
						financialPrice: this.contractAmount(this.stageInfos?.buildLandRegisterSourceCost),
						orderPrice: this.contractAmount(this.stageInfos?.buildLandRegisterOtherCost),
						subtotal: this.contractAmount(this.stageInfos?.buildLandRegisterTotalCost),
						waitDelivered: 0,
					})
				}
				if(this.speedCount(this.stageInfos?.buildLandSpeed) > 0){
					// this.speedCountAuditCost(this.stageInfos?.buildLandSpeed);
					fundsDataSource.push(
					{
						fundType: this.speedCountAuditCost(this.stageInfos?.buildLandSpeed) ? '三通一平支出（已审计)' : '三通一平支出',
						contractPrice: this.speedCountAuditCost(this.stageInfos?.buildLandSpeed) ? this.auditAllCost : this.speedCount(this.stageInfos?.buildLandSpeed),
						specialPrice: this.speedCountPayment(this.stageInfos?.buildLandSpeed, 0),
						companyPrice: this.speedCountPayment(this.stageInfos?.buildLandSpeed, 1),
						financialPrice: this.speedCountPayment(this.stageInfos?.buildLandSpeed, 2),
						orderPrice: this.speedCountPayment(this.stageInfos?.buildLandSpeed, 3),
						subtotal: this.totalSpeedPayment(this.stageInfos?.buildLandSpeed,1),
						waitDelivered: this.auditAllCost ?
						this.formatNumber(this.auditAllCost - this.totalSpeedPayment(this.stageInfos?.buildLandSpeed,1)):
              this.formatNumber(this.speedCount(this.stageInfos?.buildLandSpeed) - this.totalSpeedPayment(this.stageInfos?.buildLandSpeed,1))
					})
				}
				if(this.speedMount(this.stageInfos?.buildLandCostAuditTotalCost, this.stageInfos?.buildLandCostAuditPaymentFile) > 0){
					fundsDataSource.push(
					{
						fundType: '三通一平审计',
						contractPrice: this.speedMount(this.stageInfos?.buildLandCostAuditTotalCost, this.stageInfos
							?.buildLandCostAuditPaymentFile),
						specialPrice: this.speedPayment(this.stageInfos?.buildLandCostAuditPaymentFile, 0),
						companyPrice: this.speedPayment(this.stageInfos?.buildLandCostAuditPaymentFile, 1),
						financialPrice: this.speedPayment(this.stageInfos?.buildLandCostAuditPaymentFile, 2),
						orderPrice: this.speedPayment(this.stageInfos?.buildLandCostAuditPaymentFile, 3),
						subtotal: this.totalSpeedPayment(this.stageInfos?.buildLandCostAuditPaymentFile),
						waitDelivered: this.formatNumber(this.speedMount(this.stageInfos?.buildLandCostAuditTotalCost, this.stageInfos
							?.buildLandCostAuditPaymentFile) - this.totalSpeedPayment(this.stageInfos
							?.buildLandCostAuditPaymentFile)),

					})
				}
				if(this.contractAmount(this.stageInfos?.buildLandResultTotalCost) > 0){
					fundsDataSource.push(
					{
						fundType: '污染状况调查费用',
						contractPrice: this.contractAmount(this.stageInfos?.buildLandResultTotalCost),
						specialPrice: this.speedPayment(this.stageInfos?.buildLandResultPaymentFile, 0),
						companyPrice: this.speedPayment(this.stageInfos?.buildLandResultPaymentFile, 1),
						financialPrice: this.speedPayment(this.stageInfos?.buildLandResultPaymentFile, 2),
						orderPrice: this.speedPayment(this.stageInfos?.buildLandResultPaymentFile, 3),
						subtotal: this.totalSpeedPayment(this.stageInfos?.buildLandResultPaymentFile),
						waitDelivered: this.formatNumber(this.contractAmount(this.stageInfos?.buildLandResultTotalCost) - this
							.totalSpeedPayment(this.stageInfos?.buildLandResultPaymentFile)),

					})
				}
				if(this.feasibilityPrice > 0){
					fundsDataSource.push(
					{
						fundType: '可研编制费用',
						contractPrice: this.feasibilityPrice || 0,
						specialPrice: this.speedPayment(this.feasibilityPayment, 0),
						companyPrice: this.speedPayment(this.feasibilityPayment, 1),
						financialPrice: this.speedPayment(this.feasibilityPayment, 2),
						orderPrice: this.speedPayment(this.feasibilityPayment, 3),
						subtotal: this.totalSpeedPayment(this.feasibilityPayment),
						waitDelivered: this.formatNumber(this.feasibilityPrice - this
							.totalSpeedPayment(this.feasibilityPayment)),
					})
				}
				if(this.designerPrice > 0){
					fundsDataSource.push(
					{
						fundType: '施工图设计费',
						contractPrice: this.designerPrice || 0,
						specialPrice: this.speedPayment(this.designerPayment, 0),
						companyPrice: this.speedPayment(this.designerPayment, 1),
						financialPrice: this.speedPayment(this.designerPayment, 2),
						orderPrice: this.speedPayment(this.designerPayment, 3),
						subtotal: this.totalSpeedPayment(this.designerPayment),
						waitDelivered: this.formatNumber(this.designerPrice - this
							.totalSpeedPayment(this.designerPayment)),

					})
				}
				if(this.checkerPrice > 0){
					fundsDataSource.push(
					{
						fundType: '图审费',
						contractPrice: this.checkerPrice || 0,
						specialPrice: this.speedPayment(this.checkerPayment, 0),
						companyPrice: this.speedPayment(this.checkerPayment, 1),
						financialPrice: this.speedPayment(this.checkerPayment, 2),
						orderPrice: this.speedPayment(this.checkerPayment, 3),
						subtotal: this.totalSpeedPayment(this.checkerPayment),
						waitDelivered: this.formatNumber(this.checkerPrice - this
							.totalSpeedPayment(this.checkerPayment)),
					})
				}
				if(this.surveyorPrice > 0){
					fundsDataSource.push(
					{
						fundType: '测绘费',
						contractPrice: this.surveyorPrice || 0,
						specialPrice: this.speedPayment(this.surveyorPayment, 0),
						companyPrice: this.speedPayment(this.surveyorPayment, 1),
						financialPrice: this.speedPayment(this.surveyorPayment, 2),
						orderPrice: this.speedPayment(this.surveyorPayment, 3),
						subtotal: this.totalSpeedPayment(this.surveyorPayment),
						waitDelivered: this.formatNumber(this.surveyorPrice - this
							.totalSpeedPayment(this.surveyorPayment)),

					})
				}
				if(this.geologicalPrice > 0){
					fundsDataSource.push(
					{
						fundType: '地勘费',
						contractPrice: this.geologicalPrice || 0,
						specialPrice: this.speedPayment(this.geologicalPayment, 0),
						companyPrice: this.speedPayment(this.geologicalPayment, 1),
						financialPrice: this.speedPayment(this.geologicalPayment, 2),
						orderPrice: this.speedPayment(this.geologicalPayment, 3),
						subtotal: this.totalSpeedPayment(this.geologicalPayment),
						waitDelivered: this.formatNumber(this.geologicalPrice - this
							.totalSpeedPayment(this.geologicalPayment)),

					})
				}
				if(this.budgetingPrice > 0){
					fundsDataSource.push(
					{
						fundType: '预算编制费',
						contractPrice: this.budgetingPrice || 0,
						specialPrice: this.speedPayment(this.budgetingPayment, 0),
						companyPrice: this.speedPayment(this.budgetingPayment, 1),
						financialPrice: this.speedPayment(this.budgetingPayment, 2),
						orderPrice: this.speedPayment(this.budgetingPayment, 3),
						subtotal: this.totalSpeedPayment(this.budgetingPayment),
						waitDelivered: this.formatNumber(this.budgetingPrice - this.totalSpeedPayment(this.budgetingPayment)),
					})
				}
				if(this.budgetPrice > 0){
					fundsDataSource.push(
					{
						fundType: '预算评审费',
						contractPrice: this.budgetPrice || 0,
						specialPrice: this.speedPayment(this.budgetPayment, 0),
						companyPrice: this.speedPayment(this.budgetPayment, 1),
						financialPrice: this.speedPayment(this.budgetPayment, 2),
						orderPrice: this.speedPayment(this.budgetPayment, 3),
						subtotal: this.totalSpeedPayment(this.budgetPayment),
						waitDelivered: this.formatNumber(this.budgetPrice - this.totalSpeedPayment(this.budgetPayment)),
					})
				}
				if(this.agentPrice > 0){
					fundsDataSource.push(
					{
						fundType: '招标代理费',
						contractPrice: this.agentPrice || 0,
						specialPrice: this.speedPayment(this.agentPayment, 0),
						companyPrice: this.speedPayment(this.agentPayment, 1),
						financialPrice: this.speedPayment(this.agentPayment, 2),
						orderPrice: this.speedPayment(this.agentPayment, 3),
						subtotal: this.totalSpeedPayment(this.agentPayment),
						waitDelivered: this.formatNumber(this.agentPrice - this
							.totalSpeedPayment(this.agentPayment)),
					})
				}
				if(this.contractAmount(this.stageInfos?.inviteTendersWatcherPrice) > 0){
					fundsDataSource.push(
					{
						fundType: '监理费',
						contractPrice: this.contractAmount(this.stageInfos?.inviteTendersWatcherPrice),
						specialPrice: this.speedPayment(this.stageInfos?.inviteTendersWatcherPayment, 0),
						companyPrice: this.speedPayment(this.stageInfos?.inviteTendersWatcherPayment, 1),
						financialPrice: this.speedPayment(this.stageInfos?.inviteTendersWatcherPayment, 2),
						orderPrice: this.speedPayment(this.stageInfos?.inviteTendersWatcherPayment, 3),
						subtotal: this.totalSpeedPayment(this.stageInfos?.inviteTendersWatcherPayment),
						waitDelivered: this.formatNumber(this.contractAmount(this.stageInfos?.inviteTendersWatcherPrice) - this
							.totalSpeedPayment(this.stageInfos?.inviteTendersWatcherPayment)),

					})
				}
				if(this.contractAmount(this.stageInfos?.inviteTendersFlowablePrice) > 0){
					fundsDataSource.push(
					{
						fundType: '跟踪审计费',
						contractPrice: this.contractAmount(this.stageInfos?.inviteTendersFlowablePrice),
						specialPrice: this.speedPayment(this.stageInfos?.inviteTendersFlowablePayment, 0),
						companyPrice: this.speedPayment(this.stageInfos?.inviteTendersFlowablePayment, 1),
						financialPrice: this.speedPayment(this.stageInfos?.inviteTendersFlowablePayment, 2),
						orderPrice: this.speedPayment(this.stageInfos?.inviteTendersFlowablePayment, 3),
						subtotal: this.totalSpeedPayment(this.stageInfos?.inviteTendersFlowablePayment),
						waitDelivered: this.formatNumber(this.contractAmount(this.stageInfos?.inviteTendersFlowablePrice) - this
							.totalSpeedPayment(this.stageInfos?.inviteTendersFlowablePayment)),

					})
				}
				if(this.contractAmount(this.stageInfos?.inviteTendersSafPrice) > 0){
					fundsDataSource.push(
					{
						fundType: '全过程咨询费',
						contractPrice: this.contractAmount(this.stageInfos?.inviteTendersSafPrice),
						specialPrice: this.speedPayment(this.stageInfos?.inviteTendersSafPayment, 0),
						companyPrice: this.speedPayment(this.stageInfos?.inviteTendersSafPayment, 1),
						financialPrice: this.speedPayment(this.stageInfos?.inviteTendersSafPayment, 2),
						orderPrice: this.speedPayment(this.stageInfos?.inviteTendersSafPayment, 3),
						subtotal: this.totalSpeedPayment(this.stageInfos?.inviteTendersSafPayment),
						waitDelivered: this.formatNumber(this.contractAmount(this.stageInfos?.inviteTendersSafPrice) - this
							.totalSpeedPayment(this.stageInfos?.inviteTendersSafPayment)),

					})
				}
				if( this.contractAmount(this.stageInfos?.constructionInquiryCost) > 0){
					fundsDataSource.push(
					{
						fundType: '工程项目设备询价费',
						contractPrice: this.contractAmount(this.stageInfos?.constructionInquiryCost),
						specialPrice: this.speedPayment(this.stageInfos?.constructionInquiryPayment, 0),
						companyPrice: this.speedPayment(this.stageInfos?.constructionInquiryPayment, 1),
						financialPrice: this.speedPayment(this.stageInfos?.constructionInquiryPayment, 2),
						orderPrice: this.speedPayment(this.stageInfos?.constructionInquiryPayment, 3),
						subtotal: this.totalSpeedPayment(this.stageInfos?.constructionInquiryPayment),
						waitDelivered: this.formatNumber(this.contractAmount(this.stageInfos?.constructionInquiryCost) - this
							.totalSpeedPayment(this.stageInfos?.constructionInquiryPayment)),

					})
				}
				if(this.contractAmount(this.stageInfos?.inviteTendersSuccessPrice) > 0){
					fundsDataSource.push(
					{
						fundType: this.speedAuditCost(this.stageInfos?.constructionPayment) ? '工程款（已审计)' : '工程款',
						contractPrice: this.auditProjectPay ? this.auditProjectPay : this.contractAmount(this.stageInfos?.inviteTendersSuccessPrice),
						specialPrice: this.speedPayment(this.stageInfos?.constructionPayment, 0),
						companyPrice: this.speedPayment(this.stageInfos?.constructionPayment, 1),
						financialPrice: this.speedPayment(this.stageInfos?.constructionPayment, 2),
						orderPrice: this.speedPayment(this.stageInfos?.constructionPayment, 3),
						subtotal: this.totalSpeedPayment(this.stageInfos?.constructionPayment),
						waitDelivered: this.auditProjectPay ?
						 this.formatNumber(this.auditProjectPay - this.totalSpeedPayment(this.stageInfos?.constructionPayment))
						: this.formatNumber(this.contractAmount(this.stageInfos?.inviteTendersSuccessPrice) - this.totalSpeedPayment(this.stageInfos?.constructionPayment)),

					})
				}
				if(this.contractAmount(this.stageInfos?.constructionAuditCost) > 0){
					fundsDataSource.push(
					{
						fundType: '审计费',
						contractPrice: this.contractAmount(this.stageInfos?.constructionAuditCost),
						specialPrice: this.speedPayment(this.stageInfos?.constructionAuditPayment, 0),
						companyPrice: this.speedPayment(this.stageInfos?.constructionAuditPayment, 1),
						financialPrice: this.speedPayment(this.stageInfos?.constructionAuditPayment, 2),
						orderPrice: this.speedPayment(this.stageInfos?.constructionAuditPayment, 3),
						subtotal: this.totalSpeedPayment(this.stageInfos?.constructionAuditPayment),
						waitDelivered: this.formatNumber(this.contractAmount(this.stageInfos?.constructionAuditCost) - this
							.totalSpeedPayment(this.stageInfos?.constructionAuditPayment)),

					})
				}
				const list = this.initcompletionOtherFun(this.stageInfos?.completionOther) // 验收 第三方费用

				if(list.length !== 0){
					fundsDataSource = [...fundsDataSource,...list];
				}

				const OtherList = this.completionOtherFun(this.stageInfos?.completionOther,1);
				const approvalList = this.approvalSpeedOtherFun(this.stageInfos?.projectApprovalSpeedOther);
				if(approvalList){
					fundsDataSource.push(...approvalList);
				}
				if(OtherList){
					fundsDataSource.push(...OtherList);
				}
				fundsDataSource.push(
					{
						fundType: '合计',
						contractPrice: 0,
						specialPrice: 0,
						companyPrice: 0,
						financialPrice: 0,
						orderPrice: 0,
						subtotal: 0,
						waitDelivered: 0,
					}
				)
				this.fundsDataSource = fundsDataSource;
			},
			initStagePriceDataSource(){//初始化建设用地储备
				let list = [];
				if(this.stageInfos?.buildLandSourceTotalCost > 0){
					list.push({
						fundType: '征拆资金',
						contractPrice: this.contractAmount(this.stageInfos?.buildLandSourceTotalCost || 0),
						specialPrice: this.contractAmount(this.stageInfos?.buildLandSourceSpecialCost || 0),
						companyPrice: this.contractAmount(this.stageInfos?.buildLandSourceCompanyCost || 0),
						financialPrice: this.contractAmount(this.stageInfos?.buildLandSourceSourceCost || 0),
						orderPrice: this.contractAmount(this.stageInfos?.buildLandSourceOtherCost || 0),
						subtotal: this.contractAmount(this.stageInfos?.buildLandSourceTotalCost || 0),
						waitDelivered: 0,
					})
				}
				if(this.stageInfos?.buildLandNatureMapCost > 0){
					list.push({
						fundType: '林地测绘费用',
						contractPrice: this.contractAmount(this.stageInfos?.buildLandNatureMapCost),
						specialPrice: this.contractAmount(this.stageInfos?.buildLandNatureMapSpecialCost),
						companyPrice: this.contractAmount(this.stageInfos?.buildLandNatureMapCompanyCost),
						financialPrice: this.contractAmount(this.stageInfos?.buildLandNatureMapSourceCost),
						orderPrice: this.contractAmount(this.stageInfos?.buildLandNatureMapOtherCost),
						subtotal: this.contractAmount(this.stageInfos?.buildLandNatureMapCost),
						waitDelivered: 0,
					})
				}
				if(this.stageInfos?.buildLandNatureApprovalCost > 0){
					list.push({
						fundType: '林地报批费用',
						contractPrice: this.contractAmount(this.stageInfos?.buildLandNatureApprovalCost),
						specialPrice: this.contractAmount(this.stageInfos?.buildLandNatureApprovalSpecialCost),
						companyPrice: this.contractAmount(this.stageInfos?.buildLandNatureApprovalCompanyCost),
						financialPrice: this.contractAmount(this.stageInfos?.buildLandNatureApprovalSourceCost),
						orderPrice: this.contractAmount(this.stageInfos?.buildLandNatureApprovalOtherCost),
						subtotal: this.contractAmount(this.stageInfos?.buildLandNatureApprovalCost),
						waitDelivered: 0,
					})
				}
				if(this.stageInfos?.buildLandApprovalCost > 0){
					list.push({
						fundType: '土地报批费',
						contractPrice: this.contractAmount(this.stageInfos?.buildLandApprovalCost || 0),
						specialPrice: this.stageInfos?.buildLandApprovalCostSource == 0 ? this.contractAmount(this
							.stageInfos?.buildLandApprovalCost) : 0,
						companyPrice: this.stageInfos?.buildLandApprovalCostSource == 1 ? this.contractAmount(this
							.stageInfos?.buildLandApprovalCost) : 0,
						financialPrice: this.stageInfos?.buildLandApprovalCostSource == 2 ? this.contractAmount(this
							.stageInfos?.buildLandApprovalCost) : 0,
						orderPrice: this.stageInfos?.buildLandApprovalCostSource == 3 ? this.contractAmount(this
							.stageInfos?.buildLandApprovalCost) : 0,
						subtotal: this.contractAmount(this.stageInfos?.buildLandApprovalCost || 0),
						waitDelivered: 0,
					})
				}
				if(this.stageInfos?.buildLandGainTotalCost > 0){
					list.push({
						fundType: this.stageInfos?.buildLandGainType == 0 ? '土地出让金' : this.stageInfos?.buildLandGainType == 1 ? '有偿划拨支付资金”' : '土地转让金' ,
						contractPrice: this.contractAmount(this.stageInfos?.buildLandGainTotalCost),
						specialPrice: this.contractAmount(this.stageInfos?.buildLandGainSpecialCost),
						companyPrice: this.contractAmount(this.stageInfos?.buildLandGainCompanyCost),
						financialPrice: this.contractAmount(this.stageInfos?.buildLandGainSourceCost),
						orderPrice: this.contractAmount(this.stageInfos?.buildLandGainOtherCost),
						subtotal: this.contractAmount(this.stageInfos?.buildLandGainTotalCost),
						waitDelivered: 0,
					})
				}
				if(this.stageInfos?.buildLandRegisterTotalCost > 0){
					list.push({
						fundType: '办证费用',
						contractPrice: this.contractAmount(this.stageInfos?.buildLandRegisterTotalCost),
						specialPrice: this.contractAmount(this.stageInfos?.buildLandRegisterSpecialCost),
						companyPrice: this.contractAmount(this.stageInfos?.buildLandRegisterCompanyCost),
						financialPrice: this.contractAmount(this.stageInfos?.buildLandRegisterSourceCost),
						orderPrice: this.contractAmount(this.stageInfos?.buildLandRegisterOtherCost),
						subtotal: this.contractAmount(this.stageInfos?.buildLandRegisterTotalCost),
						waitDelivered: 0,
					})
				}
				if(this.speedCount(this.stageInfos?.buildLandSpeed) > 0){
					// this.speedCountAuditCost(this.stageInfos?.buildLandSpeed);
					list.push(
					{
						fundType: this.speedCountAuditCost(this.stageInfos?.buildLandSpeed) ? '三通一平支出（已审计)' : '三通一平支出',
						contractPrice: this.speedCountAuditCost(this.stageInfos?.buildLandSpeed)? this.auditAllCost : this.speedCount(this.stageInfos?.buildLandSpeed),
						specialPrice: this.speedCountPayment(this.stageInfos?.buildLandSpeed, 0),
						companyPrice: this.speedCountPayment(this.stageInfos?.buildLandSpeed, 1),
						financialPrice: this.speedCountPayment(this.stageInfos?.buildLandSpeed, 2),
						orderPrice: this.speedCountPayment(this.stageInfos?.buildLandSpeed, 3),
						subtotal: this.totalSpeedPayment(this.stageInfos?.buildLandSpeed,1),
						waitDelivered: this.auditAllCost ?
              this.formatNumber(this.auditAllCost - this.totalSpeedPayment(this.stageInfos?.buildLandSpeed,1)):
              this.formatNumber(this.speedCount(this.stageInfos?.buildLandSpeed) - this.totalSpeedPayment(this.stageInfos?.buildLandSpeed,1))
					})
				}
				if(this.speedMount(this.stageInfos?.buildLandCostAuditTotalCost, this.stageInfos?.buildLandCostAuditPaymentFile) > 0){
					list.push({
						fundType: '三通一平审计',
						contractPrice: this.speedMount(this.stageInfos?.buildLandCostAuditTotalCost, this.stageInfos
							?.buildLandCostAuditPaymentFile),
						specialPrice: this.speedPayment(this.stageInfos?.buildLandCostAuditPaymentFile, 0),
						companyPrice: this.speedPayment(this.stageInfos?.buildLandCostAuditPaymentFile, 1),
						financialPrice: this.speedPayment(this.stageInfos?.buildLandCostAuditPaymentFile, 2),
						orderPrice: this.speedPayment(this.stageInfos?.buildLandCostAuditPaymentFile, 3),
						subtotal: this.totalSpeedPayment(this.stageInfos?.buildLandCostAuditPaymentFile),
						waitDelivered: this.formatNumber(this.speedMount(this.stageInfos?.buildLandCostAuditTotalCost, this.stageInfos
							?.buildLandCostAuditPaymentFile) - this.totalSpeedPayment(this.stageInfos
							?.buildLandCostAuditPaymentFile)),
					})
				}
				if(this.contractAmount(this.stageInfos?.buildLandResultTotalCost) > 0){
					list.push({
						fundType: '污染状况调查费用',
						contractPrice: this.contractAmount(this.stageInfos?.buildLandResultTotalCost),
						specialPrice: this.speedPayment(this.stageInfos?.buildLandResultPaymentFile, 0),
						companyPrice: this.speedPayment(this.stageInfos?.buildLandResultPaymentFile, 1),
						financialPrice: this.speedPayment(this.stageInfos?.buildLandResultPaymentFile, 2),
						orderPrice: this.speedPayment(this.stageInfos?.buildLandResultPaymentFile, 3),
						subtotal: this.totalSpeedPayment(this.stageInfos?.buildLandResultPaymentFile),
						waitDelivered: this.formatNumber(this.contractAmount(this.stageInfos?.buildLandResultTotalCost) - this
							.totalSpeedPayment(this.stageInfos?.buildLandResultPaymentFile))
					})
				}
				list.push({
					fundType: '合计',
					contractPrice: 0,
					specialPrice: 0,
					companyPrice: 0,
					financialPrice: 0,
					orderPrice: 0,
					subtotal: 0,
					waitDelivered: 0,
				})

				this.stagePriceDataSource = list;
			},
			initTendersDataSource(){//初始化项目招标费用表
				let list = [];
				if(this.contractAmount(this.stageInfos?.inviteTendersWatcherPrice) > 0){
					list.push({
						fundType: '监理费',
						contractPrice: this.contractAmount(this.stageInfos?.inviteTendersWatcherPrice),
						specialPrice: this.speedPayment(this.stageInfos?.inviteTendersWatcherPayment, 0),
						companyPrice: this.speedPayment(this.stageInfos?.inviteTendersWatcherPayment, 1),
						financialPrice: this.speedPayment(this.stageInfos?.inviteTendersWatcherPayment, 2),
						orderPrice: this.speedPayment(this.stageInfos?.inviteTendersWatcherPayment, 3),
						subtotal: this.totalSpeedPayment(this.stageInfos?.inviteTendersWatcherPayment),
						waitDelivered: this.formatNumber(this.contractAmount(this.stageInfos?.inviteTendersWatcherPrice) - this.totalSpeedPayment(this.stageInfos?.inviteTendersWatcherPayment)),

					})
				}
				if(this.contractAmount(this.stageInfos?.inviteTendersFlowablePrice) > 0){
					list.push({
						fundType: '跟踪审计费',
						contractPrice: this.contractAmount(this.stageInfos?.inviteTendersFlowablePrice),
						specialPrice: this.speedPayment(this.stageInfos?.inviteTendersFlowablePayment, 0),
						companyPrice: this.speedPayment(this.stageInfos?.inviteTendersFlowablePayment, 1),
						financialPrice: this.speedPayment(this.stageInfos?.inviteTendersFlowablePayment, 2),
						orderPrice: this.speedPayment(this.stageInfos?.inviteTendersFlowablePayment, 3),
						subtotal: this.totalSpeedPayment(this.stageInfos?.inviteTendersFlowablePayment),
						waitDelivered: this.formatNumber(this.contractAmount(this.stageInfos?.inviteTendersFlowablePrice) - this.totalSpeedPayment(this.stageInfos?.inviteTendersFlowablePayment)),

					})
				}
				if(this.contractAmount(this.stageInfos?.inviteTendersSafPrice) > 0){
					list.push({
						fundType: '全过程咨询费',
						contractPrice: this.contractAmount(this.stageInfos?.inviteTendersSafPrice),
						specialPrice: this.speedPayment(this.stageInfos?.inviteTendersSafPayment, 0),
						companyPrice: this.speedPayment(this.stageInfos?.inviteTendersSafPayment, 1),
						financialPrice: this.speedPayment(this.stageInfos?.inviteTendersSafPayment, 2),
						orderPrice: this.speedPayment(this.stageInfos?.inviteTendersSafPayment, 3),
						subtotal: this.totalSpeedPayment(this.stageInfos?.inviteTendersSafPayment),
						waitDelivered: this.formatNumber(this.contractAmount(this.stageInfos?.inviteTendersSafPrice) - this.totalSpeedPayment(this.stageInfos?.inviteTendersSafPayment)),

					})
				}
				list.push({
					fundType: '合计',
					contractPrice: 0,
					specialPrice: 0,
					companyPrice: 0,
					financialPrice: 0,
					orderPrice: 0,
					subtotal: 0,
					waitDelivered: 0,

				})
				this.tendersDataCost = list;
			},
			initCertificate(){//初始化办证时间表
				let obj = {
					certificate:'办证时间',
					buildLandNatureAuditTime:this.transDateTime(this.stageInfos?.buildLandNatureAuditTime,1),
					buildLandRegisterPermitTime: this.stageInfos?.projectApprovalPermitLandTime ? this.transDateTime(this.stageInfos?.projectApprovalPermitLandTime,1) : this.transDateTime(this.stageInfos?.buildLandRegisterPermitTime,1),
					buildLandRegisterRegisterTime:this.transDateTime(this.stageInfos?.buildLandRegisterRegisterTime,1),
					projectApprovalReviewTime: this.transDateTime(this.stageInfos?.projectApprovalReviewTime,1),
					// projectApprovalOpinionTime:this.transDateTime(this.stageInfos?.projectApprovalOpinionTime,1),
					projectApprovalFeasibilityTime: this.transDateTime(this.stageInfos?.projectApprovalFeasibilityTime,1),
					projectApprovalPermitProjectTime:this.transDateTime(this.stageInfos?.projectApprovalPermitProjectTime,1),
					projectApprovalRiskTime:this.transDateTime(this.stageInfos?.projectApprovalRiskTime,1),
					projectApprovalDesignTime:this.transDateTime(this.stageInfos?.projectApprovalDesignTime,1),
					projectApprovalEnvApprovalTime:this.transDateTime(this.stageInfos?.projectApprovalEnvApprovalTime,1),
					projectApprovalEcTime:this.transDateTime(this.stageInfos?.projectApprovalEcTime,1),
					projectApprovalFireTime:this.transDateTime(this.stageInfos?.projectApprovalFireTime,1),
					projectApprovalPadTime:this.transDateTime(this.stageInfos?.projectApprovalPadTime,1),
					constructionPermitBuildTime:this.transDateTime(this.stageInfos?.constructionPermitBuildTime,1),
					constructionPermitStartReportTime:this.transDateTime(this.stageInfos?.constructionPermitStartReportTime,1),
					constructionChangeResponseTime:this.transDateTime(this.stageInfos?.constructionChangeResponseTime,1),
					constructionInquiryTime:this.transDateTime(this.stageInfos?.constructionInquiryTime,1),
				};
				this.fundsDataCertificate.push(obj);
			},
			initapprovalDataCost(){
				let list = [];
				if(this.feasibilityPrice > 0){
					list.push(
					{
						fundType: '可研编制费用',
						contractPrice: this.feasibilityPrice || 0,
						specialPrice: this.speedPayment(this.feasibilityPayment, 0),
						companyPrice: this.speedPayment(this.feasibilityPayment, 1),
						financialPrice: this.speedPayment(this.feasibilityPayment, 2),
						orderPrice: this.speedPayment(this.feasibilityPayment, 3),
						subtotal: this.totalSpeedPayment(this.feasibilityPayment),
						waitDelivered: this.formatNumber(this.feasibilityPrice - this
							.totalSpeedPayment(this.feasibilityPayment)),
					})
				}
				if(this.designerPrice > 0){
					list.push(
					{
						fundType: '施工图设计费',
						contractPrice: this.designerPrice || 0,
						specialPrice: this.speedPayment(this.designerPayment, 0),
						companyPrice: this.speedPayment(this.designerPayment, 1),
						financialPrice: this.speedPayment(this.designerPayment, 2),
						orderPrice: this.speedPayment(this.designerPayment, 3),
						subtotal: this.totalSpeedPayment(this.designerPayment),
						waitDelivered: this.formatNumber(this.designerPrice - this
							.totalSpeedPayment(this.designerPayment)),

					})
				}
				if(this.checkerPrice > 0){
					list.push(
					{
						fundType: '图审费',
						contractPrice: this.checkerPrice || 0,
						specialPrice: this.speedPayment(this.checkerPayment, 0),
						companyPrice: this.speedPayment(this.checkerPayment, 1),
						financialPrice: this.speedPayment(this.checkerPayment, 2),
						orderPrice: this.speedPayment(this.checkerPayment, 3),
						subtotal: this.totalSpeedPayment(this.checkerPayment),
						waitDelivered: this.formatNumber(this.checkerPrice - this
							.totalSpeedPayment(this.checkerPayment)),
					})
				}
				if(this.surveyorPrice > 0){
					list.push(
					{
						fundType: '测绘费',
						contractPrice: this.surveyorPrice || 0,
						specialPrice: this.speedPayment(this.surveyorPayment, 0),
						companyPrice: this.speedPayment(this.surveyorPayment, 1),
						financialPrice: this.speedPayment(this.surveyorPayment, 2),
						orderPrice: this.speedPayment(this.surveyorPayment, 3),
						subtotal: this.totalSpeedPayment(this.surveyorPayment),
						waitDelivered: this.formatNumber(this.surveyorPrice - this
							.totalSpeedPayment(this.surveyorPayment)),

					})
				}
				if(this.geologicalPrice > 0){
					list.push(
					{
						fundType: '地勘费',
						contractPrice: this.geologicalPrice || 0,
						specialPrice: this.speedPayment(this.geologicalPayment, 0),
						companyPrice: this.speedPayment(this.geologicalPayment, 1),
						financialPrice: this.speedPayment(this.geologicalPayment, 2),
						orderPrice: this.speedPayment(this.geologicalPayment, 3),
						subtotal: this.totalSpeedPayment(this.geologicalPayment),
						waitDelivered: this.formatNumber(this.geologicalPrice - this
							.totalSpeedPayment(this.geologicalPayment)),

					})
				}
				if(this.budgetingPrice > 0){
					list.push(
					{
						fundType: '预算编制费',
						contractPrice: this.budgetingPrice || 0,
						specialPrice: this.speedPayment(this.budgetingPayment, 0),
						companyPrice: this.speedPayment(this.budgetingPayment, 1),
						financialPrice: this.speedPayment(this.budgetingPayment, 2),
						orderPrice: this.speedPayment(this.budgetingPayment, 3),
						subtotal: this.totalSpeedPayment(this.budgetingPayment),
						waitDelivered: this.formatNumber(this.budgetingPrice - this.totalSpeedPayment(this.budgetingPayment)),
					})
				}
				if(this.budgetPrice > 0){
					list.push(
					{
						fundType: '预算评审费',
						contractPrice: this.budgetPrice || 0,
						specialPrice: this.speedPayment(this.budgetPayment, 0),
						companyPrice: this.speedPayment(this.budgetPayment, 1),
						financialPrice: this.speedPayment(this.budgetPayment, 2),
						orderPrice: this.speedPayment(this.budgetPayment, 3),
						subtotal: this.totalSpeedPayment(this.budgetPayment),
						waitDelivered: this.formatNumber(this.budgetPrice - this.totalSpeedPayment(this.budgetPayment)),
					})
				}
				if(this.agentPrice > 0){
					list.push(
					{
						fundType: '招标代理费',
						contractPrice: this.agentPrice || 0,
						specialPrice: this.speedPayment(this.agentPayment, 0),
						companyPrice: this.speedPayment(this.agentPayment, 1),
						financialPrice: this.speedPayment(this.agentPayment, 2),
						orderPrice: this.speedPayment(this.agentPayment, 3),
						subtotal: this.totalSpeedPayment(this.agentPayment),
						waitDelivered: this.formatNumber(this.agentPrice - this
							.totalSpeedPayment(this.agentPayment)),
					})
				}
				// list.push({
				// 	fundType: '合计',
				// 	contractPrice: 0,
				// 	specialPrice: 0,
				// 	companyPrice: 0,
				// 	financialPrice: 0,
				// 	orderPrice: 0,
				// 	subtotal: 0,
				// 	waitDelivered: 0,
				// })
				this.approvalDataCost = list;
			},
			initApplyDataCost(){
				let list = [];
				if(this.contractAmount(this.stageInfos?.constructionInquiryCost) > 0){
					list.push({
						fundType: '工程项目设备询价费',
						contractPrice: this.contractAmount(this.stageInfos?.constructionInquiryCost),
						audit: 0,
						addAmountPrice: 0,
						specialPrice: this.speedPayment(this.stageInfos?.constructionInquiryPayment, 0),
						companyPrice: this.speedPayment(this.stageInfos?.constructionInquiryPayment, 1),
						financialPrice: this.speedPayment(this.stageInfos?.constructionInquiryPayment, 2),
						orderPrice: this.speedPayment(this.stageInfos?.constructionInquiryPayment, 3),
						subtotal: this.totalSpeedPayment(this.stageInfos?.constructionInquiryPayment),
						waitDelivered: this.formatNumber(this.contractAmount(this.stageInfos?.constructionInquiryCost) - this
							.totalSpeedPayment(this.stageInfos?.constructionInquiryPayment)),
					})
				}
				if(this.contractAmount(this.stageInfos?.inviteTendersSuccessPrice) > 0){
					list.push({
						fundType: this.speedAuditCost(this.stageInfos?.constructionPayment) ? '工程款（已审计)' : '工程款',
						contractPrice: this.auditProjectPay ? this.auditProjectPay : this.contractAmount(this.stageInfos?.inviteTendersSuccessPrice),
						audit: this.auditProjectPay,
						addAmountPrice: this.contractAmount(this.stageInfos?.constructionChangeAddAmount),
						specialPrice: this.speedPayment(this.stageInfos?.constructionPayment, 0),
						companyPrice: this.speedPayment(this.stageInfos?.constructionPayment, 1),
						financialPrice: this.speedPayment(this.stageInfos?.constructionPayment, 2),
						orderPrice: this.speedPayment(this.stageInfos?.constructionPayment, 3),
						subtotal: this.totalSpeedPayment(this.stageInfos?.constructionPayment),
						waitDelivered: this.auditProjectPay ?
							this.formatNumber(this.auditProjectPay - this.totalSpeedPayment(this.stageInfos?.constructionPayment))
							: this.formatNumber(this.contractAmount(this.stageInfos?.inviteTendersSuccessPrice) - this.totalSpeedPayment(this.stageInfos?.constructionPayment)),
					})
				}
				if(this.contractAmount(this.stageInfos?.constructionAuditCost) > 0){
					list.push({
						fundType: '审计费',
						contractPrice: this.contractAmount(this.stageInfos?.constructionAuditCost),
						audit: 0,
						addAmountPrice: 0,
						specialPrice: this.speedPayment(this.stageInfos?.constructionAuditPayment, 0),
						companyPrice: this.speedPayment(this.stageInfos?.constructionAuditPayment, 1),
						financialPrice: this.speedPayment(this.stageInfos?.constructionAuditPayment, 2),
						orderPrice: this.speedPayment(this.stageInfos?.constructionAuditPayment, 3),
						subtotal: this.totalSpeedPayment(this.stageInfos?.constructionAuditPayment, 0),
						waitDelivered: this.formatNumber(this.contractAmount(this.stageInfos?.constructionAuditCost) - this.totalSpeedPayment(this.stageInfos?.constructionAuditPayment)),
					})
				}
				list.push({
					fundType: '合计',
					contractPrice: 0,
					audit: 0,
					addAmountPrice: 0,
					specialPrice: 0,
					companyPrice: 0,
					financialPrice: 0,
					orderPrice: 0,
					subtotal: 0,
					waitDelivered: 0,
				})
				this.applyDataCost = list;
			},
			// 判断是否有审计金额
			speedAuditCost(payment){
				let jsPayment = (typeof payment === 'string') ? JSON.parse(payment) : payment;
				let flag = false;
				if(jsPayment){
					jsPayment.forEach((item)=>{
						if(item?.auditCost > 0){
							flag = true;
						}
					})
				}
				return flag;
			},
			init() {
				this.initFundsDateSource ();
				this.initStagePriceDataSource();
				this.initapprovalDataCost();
				this.initTendersDataSource();//初始化项目招标费用
				this.initApplyDataCost();//初始化项目使用费用
				this.initCertificate();
				this.landDataSource = [{
					landFrom: this.stageInfos?.buildLandSourceType ? (this.stageInfos?.buildLandSourceType == 0 ? '乡镇征收' : (this.stageInfos?.buildLandSourceType == 1 ? '自然资源储备中心' : '从单位或公司划拨')) : '-',
					forestryNature: this.stageInfos?.buildLandNatureHasForestry ? (this.stageInfos?.buildLandNatureHasForestry == 0 ? '涉及林业用地' : '不涉及林业用地') : '-',
					landMethods: this.stageInfos?.buildLandGainType ? (this.stageInfos?.buildLandGainType == 0 ? '摘牌' : (this.stageInfos?.buildLandGainType == 1 ? '有偿划拨' : (this.stageInfos?.buildLandGainType == 2 ? '无偿划拨' :
							'协议转让'))) : '-',
					isApply: this.stageInfos?.buildLandUniType ? (this.stageInfos?.buildLandUniType == 0 ? '是' : '否') : '-',
					isProperty: this.stageInfos?.buildLandRegisterStatus ? (this.stageInfos?.buildLandRegisterStatus == 0 ? '已办理' : (this.stageInfos?.buildLandRegisterStatus == 1 ? '未办理' : '不需要')) : '-',
					payCheckSpeed: this.stageInfos?.buildLandSpeedStatus ? (this.stageInfos?.buildLandSpeedStatus == 0 ? '有支出' : (this.stageInfos?.buildLandSpeedStatus == 1 ? '无支出' : '不需要')) : '-',
					payCheckAudit: this.stageInfos?.buildLandCostAuditStatus ? (this.stageInfos?.buildLandCostAuditStatus == 0 ? '有审计' : (this.stageInfos?.buildLandCostAuditStatus == 1 ? '无审计' : '不需要')) : '-',
					isSurvey: this.stageInfos?.buildLandResultStatus ? (this.stageInfos?.buildLandResultStatus == 0 ? '有调查' : (this.stageInfos?.buildLandResultStatus == 1 ? '无调查' : '不需要')) : '-'
				}]
				this.approvalDataSource = [{
						isImportant: this.stageInfos?.projectApprovalIsImportant ? (this.stageInfos?.projectApprovalIsImportant == 0 ? '否' : '是') : '-',
						isPlan: this.stageInfos?.projectApprovalIsPlan ? (this.stageInfos?.projectApprovalIsPlan == 0 ? '是' : '否') : '-',
						budget: this.contractAmount(this.stageInfos?.projectApprovalReviewTotalCost),
						specialPrice: this.contractAmount(this.stageInfos?.projectApprovalSourceSpecialCost),
						companyPrice: this.contractAmount(this.stageInfos?.projectApprovalSourceCompanyCost),
						financialPrice: this.contractAmount(this.stageInfos?.projectApprovalSourceSourceCost),
						orderPrice: this.contractAmount(this.stageInfos?.projectApprovalSourceOtherCost),
						reviewStatus: this.stageInfos?.projectApprovalReviewStatus ? (this.stageInfos?.projectApprovalReviewStatus == 0 ? '已立项' : (this.stageInfos?.projectApprovalReviewStatus == 1 ? '未立项' : '不需要')) : '-',
						isApprovalBook: this.stageInfos?.projectApprovalOpinionFlag ? (this.stageInfos?.projectApprovalOpinionFlag == 0 ? '需要' : '不需要') : '-',
						isFeasibility: this.stageInfos?.projectApprovalHasFeasibility ? (this.stageInfos?.projectApprovalHasFeasibility == 0 ? '有可研' : (this.stageInfos?.projectApprovalHasFeasibility == 1 ? '无可研' : '不需要')) : '-',
						isRisk: this.stageInfos?.projectApprovalHasRisk ? (this.stageInfos?.projectApprovalHasRisk == 0 ? '有备案表' : (this.stageInfos?.projectApprovalHasRisk == 1 ? '无备案表' : '不需要')) : '-',
						isApproval: this.stageInfos?.projectApprovalHasDesign ? (this.stageInfos?.projectApprovalHasDesign == 0 ? '有初设批复' : (this.stageInfos?.projectApprovalHasDesign == 1 ? '无初设批复' : '不需要')) : '',
						isEstimate: this.stageInfos?.projectApprovalHasEnv ? (this.stageInfos?.projectApprovalHasEnv == 0 ? '有环评' : (this.stageInfos?.projectApprovalHasEnv == 1 ? '无环评' : '不需要')) : '-',
						isProtect: this.stageInfos?.projectApprovalHasKeep ? (this.stageInfos?.projectApprovalHasKeep == 0 ? '有水保方案' : (this.stageInfos?.projectApprovalHasKeep == 1 ? '无水保方案' : '不需要')) : '-',
						isFlood: this.stageInfos?.projectApprovalHasEvaluate ? (this.stageInfos?.projectApprovalHasEvaluate == 0 ? '有防洪评价' : (this.stageInfos?.projectApprovalHasEvaluate == 1 ? '无防洪评价' : '不需要')) : '-',
						isPower: this.stageInfos?.projectApprovalHasEc ? (this.stageInfos?.projectApprovalHasEc == 0 ? '有节能审批' : (this.stageInfos?.projectApprovalHasEc == 1 ? '无节能审批' : '不需要')) : '-',
						isFire: this.stageInfos?.projectApprovalHasFire ? (this.stageInfos?.projectApprovalHasFire == 0 ? '有消防审批' : (this.stageInfos?.projectApprovalHasFire == 1 ? '无消防审批' : '不需要')) : '-',
						isDefence: this.stageInfos?.projectApprovalHasPad ? (this.stageInfos?.projectApprovalHasPad == 0 ? '有人防审批' : (this.stageInfos?.projectApprovalHasPad == 1 ? '无人防审批' : '不需要')) : '-',
					}],
					// 项目招标
					this.tendersDataSource = [
						{
							epc: this.stageInfos?.inviteTendersSummaryType ? (this.stageInfos?.inviteTendersSummaryType == 0 ? '非EPC项目' : 'EPC项目') : '-',
							companyName:  this.stageInfos?.inviteTendersSuccessCompany || '-',
							biddingWay: this.stageInfos?.inviteTendersSuccessWay === '0' ? '公开招标' : this.stageInfos?.inviteTendersSuccessWay === '1' ? '邀请招标' : this.stageInfos?.inviteTendersSuccessWay === '2' ? '直接打包' : '-',
							watcher: this.stageInfos?.inviteTendersHasWatcher ? (this.stageInfos?.inviteTendersHasWatcher == 0 ? '有监理' : (this.stageInfos?.inviteTendersHasWatcher == 1 ? '有监理(包含在全过程咨询服务中)' : (this.stageInfos?.inviteTendersHasWatcher == 2 ? '无监理' : '不需要'))) : '-',
							watcherCompany: this.stageInfos?.inviteTendersWatcherCompany || '-',
							flowable: this.stageInfos?.inviteTendersHasFlowable ? (this.stageInfos?.inviteTendersHasFlowable == 0 ? '有跟踪审计' : (this.stageInfos?.inviteTendersHasFlowable == 1 ? '无跟踪审计' : '不需要')) : '-',
							flowableCompany: this.stageInfos?.inviteTendersFlowableCompany || '-',
							hasSaf: this.stageInfos?.inviteTendersHasSaf ? (this.stageInfos?.inviteTendersHasSaf == 0 ? '有全过程咨询' : (this.stageInfos?.inviteTendersHasSaf == 1 ? '有全过程咨询(含监理服务)' : (this.stageInfos?.inviteTendersHasSaf == 2 ? '无全过程咨询' : '不需要'))) : '-',
							safCompany: this.stageInfos?.inviteTendersSafCompany || '-'
						}
					]



					this.applyDataSource = [{
						permit: this.stageInfos?.constructionHasPermit ? (this.stageInfos?.constructionHasPermit == 0 ? '有许可证或开工报告' : '无许可证或开工报告') : '-',
						change: this.stageInfos?.constructionHasChange ? (this.stageInfos?.constructionHasChange == 0 ? '有变更' : '无变更') : '-',
						inquiry: this.stageInfos?.constructionHasInquiry ? (this.stageInfos?.constructionHasInquiry == 0 ? '有设备询价' : (this.stageInfos?.constructionHasInquiry == 1 ? '有设备询价(无询价公司)' : '无设备询价')) : '-',
						inquiryCompany: this.stageInfos?.constructionHasInquiry == 0 ? (this.stageInfos?.constructionInquiryCompany ? this.stageInfos?.constructionInquiryCompany : '-') : '-',
						schedule: this.stageInfos?.constructionSchedulePercent ? ((this.stageInfos?.constructionSchedulePercent / (Number(this.stageInfos?.inviteTendersSuccessPrice) + Number(this.stageInfos?.constructionChangeAddAmount?this.stageInfos?.constructionChangeAddAmount:0) + this.auditProjectPay))* 100).toFixed(2) + '%' : 0+'%',
						// constructionSchedulePercent
						payPercent: (this.stageInfos?.constructionSchedulePayPercent || 0) * 100 + '%',
						// audit: this.stageInfos?.constructionHasAudit == 0 ? '有审计' : (this.stageInfos?.constructionHasAudit == 1 ? '有审计(无询价公司)' : (this.stageInfos?.constructionHasAudit == 2 ? '无审计' : '不需要')),
						audit: this.stageInfos?.constructionHasAudit ? (this.stageInfos?.constructionHasAudit == 1 ? '有审计（跟踪审计）' : this.stageInfos?.inviteTendersFlowableCompany ? '有审计' : '无审计') : '-',
						// auditCompany: this.stageInfos?.constructionAuditCompany || '无审计公司'
						// 施工有无审计 ？ 施工审计 ： 招标是否有审计 ？ 招标审计公司 ： 无审计公司
						auditCompany: this.stageInfos?.constructionAuditCompany ? this.stageInfos?.constructionAuditCompany : this.stageInfos?.inviteTendersFlowableCompany ? this.stageInfos?.inviteTendersFlowableCompany : '-'
					}]

				this.finishDataSource = [{
						isHasLpi: this.stageInfos?.completionHasLpi ? (this.stageInfos?.completionHasLpi == 0 ? '有避雷检查' : (this.stageInfos?.completionHasLpi == 1 ? '无避雷检查' : '不需要')) : '-',
						isHasFire: this.stageInfos?.completionHasFire ? (this.stageInfos?.completionHasFire == 0 ? '有消防验收' : (this.stageInfos?.completionHasFire == 1 ? '无消防验收' : '不需要')) : '-',
						isHasBuild: this.stageInfos?.completionHasBuild ? (this.stageInfos?.completionHasBuild == 0 ? '有建设规划验收' : (this.stageInfos?.completionHasBuild == 1 ? '无建设规划验收' : '不需要')) : '',
						isHasEia: this.stageInfos?.completionHasEia ? (this.stageInfos?.completionHasEia == 0 ? '有环评验收' : (this.stageInfos?.completionHasEia == 1 ? '无环评验收' : '不需要')) : '-',
						isHasKeep: this.stageInfos?.completionHasKeep ? (this.stageInfos?.completionHasKeep == 0 ? '有水保验收' : (this.stageInfos?.completionHasKeep == 1 ? '无水保验收' : '不需要')) : '-',
						isHasFiling: this.stageInfos?.completionHasFiling ? (this.stageInfos?.completionHasFiling == 0 ? '有竣工验收备案' : (this.stageInfos?.completionHasFiling == 1 ? '无竣工验收备案' : '不需要')) : '-',
						accounts: this.stageInfos?.completionAccountsCost || 0
					}],
				// 竣工阶段费用
				this.finishDataCost = []

				// 文件整理
				this.fundVoucher = this.fundVoucherFun('buildLand', 0)
				this.stageFiles = this.fundVoucherFun('buildLand', 1)
				this.approvalVoucher = this.fundVoucherFun('projectApproval', 0)
				this.approvalFiles = this.fundVoucherFun('projectApproval', 1)
				this.tendersVoucher = this.fundVoucherFun('inviteTenders', 0)
				this.tendersFiles = this.fundVoucherFun('inviteTenders', 1)
				this.applyVoucher = this.fundVoucherFun('construction', 0)
				this.applyFiles = this.fundVoucherFun('construction', 1)
				this.finishVoucher = this.fundVoucherFun('completion', 0)
				this.finishFiles = this.fundVoucherFun('completion', 1)

				this.completionOtherFun(this.stageInfos?.completionOther) // 验收 第三方费用

				this.approvalSpeedOtherFun(this.stageInfos?.projectApprovalSpeedOther) // 中介费用

				// 合计
				let fundLen = this.fundsDataSource.length
				let landLen = this.stagePriceDataSource.length
				let applyLen = this.applyDataCost.length
				let approvalLen = this.approvalDataCost.length
				let tendersLen = this.tendersDataCost.length
				let finishLen = this.finishDataCost.length
				this.fundsDataSource[fundLen - 1].contractPrice = this.amountSummary(this.fundsDataSource, 'contractPrice')
				this.fundsDataSource[fundLen - 1].specialPrice = this.amountSummary(this.fundsDataSource, 'specialPrice')
				this.fundsDataSource[fundLen - 1].companyPrice = this.amountSummary(this.fundsDataSource, 'companyPrice')
				this.fundsDataSource[fundLen - 1].financialPrice = this.amountSummary(this.fundsDataSource,'financialPrice')
				this.fundsDataSource[fundLen - 1].orderPrice = this.amountSummary(this.fundsDataSource, 'orderPrice')
				this.fundsDataSource[fundLen - 1].subtotal = this.amountSummary(this.fundsDataSource, 'subtotal')
				this.fundsDataSource[fundLen - 1].waitDelivered = this.amountSummary(this.fundsDataSource, 'waitDelivered')

				this.stagePriceDataSource[landLen - 1].contractPrice = this.amountSummary(this.stagePriceDataSource, 'contractPrice')
				this.stagePriceDataSource[landLen - 1].specialPrice = this.amountSummary(this.stagePriceDataSource, 'specialPrice')
				this.stagePriceDataSource[landLen - 1].companyPrice = this.amountSummary(this.stagePriceDataSource, 'companyPrice')
				this.stagePriceDataSource[landLen - 1].financialPrice = this.amountSummary(this.stagePriceDataSource,'financialPrice')
				this.stagePriceDataSource[landLen - 1].orderPrice = this.inputNumberFormat(this.amountSummary(this.stagePriceDataSource, 'orderPrice'))
				this.stagePriceDataSource[landLen - 1].subtotal = this.amountSummary(this.stagePriceDataSource, 'subtotal')
				this.stagePriceDataSource[landLen - 1].waitDelivered = this.amountSummary(this.stagePriceDataSource, 'waitDelivered')

				if(this.approvalDataCost && this.approvalDataCost.length){
					this.approvalDataCost[approvalLen - 1].contractPrice = this.amountSummary(this.approvalDataCost, 'contractPrice')
					this.approvalDataCost[approvalLen - 1].specialPrice = this.amountSummary(this.approvalDataCost, 'specialPrice')
					this.approvalDataCost[approvalLen - 1].companyPrice = this.amountSummary(this.approvalDataCost, 'companyPrice')
					this.approvalDataCost[approvalLen - 1].financialPrice = this.amountSummary(this.approvalDataCost,'financialPrice')
					this.approvalDataCost[approvalLen - 1].orderPrice = this.amountSummary(this.approvalDataCost, 'orderPrice')
					this.approvalDataCost[approvalLen - 1].subtotal = this.amountSummary(this.approvalDataCost, 'subtotal')
					this.approvalDataCost[approvalLen - 1].waitDelivered = this.amountSummary(this.approvalDataCost, 'waitDelivered')
				}

				if(this.tendersDataCost && this.tendersDataCost.length){
					this.tendersDataCost[tendersLen - 1].contractPrice = this.amountSummary(this.tendersDataCost, 'contractPrice')
					this.tendersDataCost[tendersLen - 1].specialPrice = this.amountSummary(this.tendersDataCost, 'specialPrice')
					this.tendersDataCost[tendersLen - 1].companyPrice = this.amountSummary(this.tendersDataCost, 'companyPrice')
					this.tendersDataCost[tendersLen - 1].financialPrice = this.amountSummary(this.tendersDataCost,'financialPrice')
					this.tendersDataCost[tendersLen - 1].orderPrice = this.amountSummary(this.tendersDataCost, 'orderPrice')
					this.tendersDataCost[tendersLen - 1].subtotal = this.amountSummary(this.tendersDataCost, 'subtotal')
					this.tendersDataCost[tendersLen - 1].waitDelivered = this.amountSummary(this.tendersDataCost, 'waitDelivered')
				}

				if(this.applyDataCost && this.applyDataCost.length){
					this.applyDataCost[applyLen - 1].contractPrice = this.amountSummary(this.applyDataCost, 'contractPrice')
					this.applyDataCost[applyLen - 1].audit = this.amountSummary(this.applyDataCost, 'audit')
				    this.applyDataCost[applyLen - 1].addAmountPrice = this.amountSummary(this.applyDataCost, 'addAmountPrice')
					this.applyDataCost[applyLen - 1].specialPrice = this.amountSummary(this.applyDataCost, 'specialPrice')
					this.applyDataCost[applyLen - 1].companyPrice = this.amountSummary(this.applyDataCost, 'companyPrice')
					this.applyDataCost[applyLen - 1].financialPrice = this.amountSummary(this.applyDataCost, 'financialPrice')
					this.applyDataCost[applyLen - 1].orderPrice = this.amountSummary(this.applyDataCost, 'orderPrice')
					this.applyDataCost[applyLen - 1].subtotal = this.amountSummary(this.applyDataCost, 'subtotal')
					this.applyDataCost[applyLen - 1].waitDelivered = this.amountSummary(this.applyDataCost, 'waitDelivered')
				}


				if (this.finishDataCost && this.finishDataCost.length) {
					this.finishDataCost[finishLen - 1].contractPrice = this.amountSummary(this.finishDataCost, 'contractPrice')
					this.finishDataCost[finishLen - 1].specialPrice = this.amountSummary(this.finishDataCost, 'specialPrice')
					this.finishDataCost[finishLen - 1].companyPrice = this.amountSummary(this.finishDataCost, 'companyPrice')
					this.finishDataCost[finishLen - 1].financialPrice = this.amountSummary(this.finishDataCost, 'financialPrice')
					this.finishDataCost[finishLen - 1].orderPrice = this.amountSummary(this.finishDataCost, 'orderPrice')
					this.finishDataCost[finishLen - 1].subtotal = this.amountSummary(this.finishDataCost, 'subtotal')
					this.finishDataCost[finishLen - 1].waitAmount = this.amountSummary(this.finishDataCost, 'waitAmount')
				}

			}
		}

	}
</script>

<style scoped lang="less">
	.steps-content {
		// 1
		margin-top: 16px;
		/* border: 1px dashed #e9e9e9; */
		border-radius: 6px;
		// background-color: #fafafa;
		min-height: 200px;
		text-align: center;
		padding-top: 20px;
		padding-right: 100px;
		text-align: left;
	}

	.steps-action {
		margin-top: 24px;
		display: flex;
		justify-content: center;
	}

	.ui-styleBox {
		border: 1px solid #e9e9e9;
		border-radius: 20px;
		padding: 10px;
		margin-top: 10px;

		.ui-styleBox-title {
			font-size: 16px;
			font-weight: 400;
		}
	}

	.ui-contentBorderBox {
		display: flex;
		align-items: center
	}

	.ui-contentBorder {
		// width: 60%;
		border: 1px solid #e9e9e9;
		border-radius: 20px;
		background: #f0f8ff;
		padding: 10px;
		margin-top: 10px;
		margin-right: 10px;
	}

	.ui-contentBorderMini {
		width: 60%;
		border: 1px solid #e9e9e9;
		border-radius: 20px;
		background: #f0f8ff;
		padding: 10px;
		margin-top: 10px;
		margin-right: 10px;
	}

	.ui-contentWhiteBorder {
		width: 60%;
		border: 1px solid #e9e9e9;
		border-radius: 20px;
		background: #ffffff;
		padding: 10px;
		margin-top: 10px;
		margin-right: 10px;
	}

	.ui-contentMarginTop {
		margin-top: 10px;
		font-weight: 600;
		font-size: 18px;
	}

	.ui-employBorder {
		border: 1px solid #e9e9e9;
		padding: 10px;
		border-radius: 20px;
		background-color: #e9e9e9;
		min-width: 600px;
		/* max-width: 1500px; */
	}

	.ui-title {
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}

	.ui-childBorderStyle {
		border: 1px solid #e9e9e9;
		border-radius: 20px;
		background-color: #f0f8ff;
		padding: 10px;
	}

	.ui-SerChildBorderStyle {
		border: 1px solid #e9e9e9;
		border-radius: 20px;
		// background-color: #f0f8ff;
		padding: 10px;
		display: flex;
		align-items: center;
		// margin-left: 20px;
	}

	.ui-SerChildStyleMini {
		border: 1px solid #e9e9e9;
		border-radius: 20px;
		background-color: #f0f8ff;
		padding: 5px;
	}

	.ui-cutApart {
		// 1
		height: 20px;
	}

	.ui-inputNumWidth {
		width: 150px;
	}

	.ui-stepsTip {
		//    position: fixed;
		// right: 0;
		// display: flex;
		//    flex-direction: row-reverse;
		//    align-items: center;
		// text-align:right;
		margin-top: 10px;
		// p{
		//     padding: 0;
		//     margin: 0;
		//     margin-left: 20px;
		// }
	}

	.ui-previewBaseInfo {
		font-size: 20px;
		font-weight: 600;
		margin-left: 10px;
	}

	.ui-fileStyle {
		display: flex;
		flex-wrap: wrap;

		a {
			// min-width: 400px;
			display: inline-block;
			color: #1D1E1E;
			font-size: 14px;
			// padding: 10px;
		}
	}

	.ui-formUseLandPay-line {
		display: flex;

		.ui-formUseLandPay-price {
			width: 650px;
			margin-left: 50px;

			span {
				padding: 0 20px;
				line-height: 30px;
			}
		}

		.ui-formUseLandPay-percentage {
			width: 200px;
			margin-left: 50px;

			span {
				padding: 0 20px;
				line-height: 30px;
			}
		}
	}

	.ui-icon-style {
		margin-right: 5px !important;
		line-height: 35px !important;
	}

	.ui-tipsStyle {
		color: red;
		// line-height: 10px;
	}

	.ui-stepStyle {
		// 1
		display: flex;
		justify-content: center;
		align-items: center;
		height: 50px;
		width: 100%;
		padding-right: 20%;
		// position:fixed;
		// z-index:999;
		// top:11.5%;
		// margin-top: 10px;
		// background-color: #fff;
	}

	.ui-headerStyle {
		//1
		position: fixed;
		z-index: 999;
		top: 113px; //
		// width: 85%;
		width:calc(100% - 290px);
		margin-top: 10px;
		background-color: #fff;
	}

	.ui-height1 {
		height: 170px;
	}

	.ui-height2 {
		// 1
		height: 40px;
	}

	.ui-icon-plusStyle {
		font-size: 32px;
		// width: 48px;
		// height: 48px;
	}

	.ui-plusIconBox {
		display: flex;
		align-items: center;
	}

	.ui-moduleBox {
		// 1
		padding: 50px 10px 10px;
		// margin-bottom: 10px;
		// padding: 10px;
	}

	.ui-loaddownBtn {
		width: 85%;
		height: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed;
		bottom: 0;
		z-index: 999;
	}

	.ui-common {
		display: flex;
		flex-wrap: wrap;
	}

	.ui-common__item {
		width: 200px;
		// font-size: 12px;
		// height: 128px;
		margin-right: 30px;
		margin-bottom: 20px;
		// text-align: center;
	}

	.ui-instructions{
		padding: 10px;
		max-height: 200px;
		overflow: hidden;
		overflow-y: auto;
		margin-bottom: 30px;
		border: 1px solid #ccc;
	}

	::v-deep .ui-gray td{
       background-color: #c2c1c1;
	}

	.ui-explain{
		margin-top: 5px;
		color: #777579;
	}
	// .ant-steps-item-icon {
	//  box-sizing: content-box;
	// }
</style>
